import { FaCheck } from "react-icons/fa"
export default function CheckSelected(props) {
    const { selected } = props
    return (
        <div className={`${selected ? 'bg-indigo-700': ''} flex items-center justify-center w-4 h-4 border rounded border-indigo-300`}>
            {
                selected &&
                <FaCheck className="w-3 h-3 text-white" />
            }
        </div>
    )
}