import SlidingMenu from "../../SlidingMenu";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import DotsLoading from "../../DotsLoading";
import LeadsService from "../../../services/leads.service";
import { useNavigate } from "react-router-dom";
import { MdBusiness, MdPerson } from "react-icons/md";
export default function LeadForm(props) {
  const navigate = useNavigate();
  const {
    panelTitle = "Agregar nuevo seguimiento",
    showSlide,
    setShowSlide,
    isNew = true,
    leadKey = 0,
    lead = {},
  } = props;
  let {
    uuid = 0,
    name = "",
    lastName = "",
    email = "",
    phone = "",
    address = "",
    clientCompany = {},
  } = lead;
  //let { companyName = "", keyCompany = "", companyRfc = "",  }
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [leadType, setLeadType] = useState("person");
  const [isBusiness, setIsBusiness] = useState(true);

  useEffect(() => {
    if (!isNew) {
      reset({
        businessName: clientCompany?.name,
        businessRfc: clientCompany?.rfc,
        keyCompany: clientCompany?.keyCompany,
        rateInfo: clientCompany?.rateInfo,
        name: name,
        lastName: lastName,
        email: email,
        phone: phone,
        address: address,
      });
    }
  }, [showSlide]);

  useEffect(() => {
    if (!isNew && clientCompany !== null) {
      setIsBusiness(true);
      setLeadType("business");
    } else {
      setIsBusiness(!showSlide);
      setLeadType("person");
    }
  }, [showSlide]);

  const handleLeadType = (e) => {
    //const leadTypeBusiness = [1,2]
    setIsBusiness(e.target.value === "person" ? false : true);
    setLeadType(e.target.value);
    reset({
      businessName: "",
      businessRfc: "",
    });
  };
  const onSubmit = (props) => {
    if (isNew) {
      createLead(props);
    } else {
      updateLead(props);
    }
  };

  const createLead = (props) => {
    const businessName =
      props.businessName.trim() !== "" ? props.businessName : undefined;
    const businessRfc =
      props.businessRfc.trim() !== "" ? props.businessRfc : undefined;
    const keyCompany =
      props.keyCompany.trim() !== "" ? props.keyCompany : undefined;
    const rateInfo = props.rateInfo.trim() !== "" ? props.rateInfo : undefined;
    const clientType = leadType.trim() !== "" ? leadType : undefined;
    const params = {
      ...props,
      ...{
        platform: "crm",
        language: "es",
        marketingConsent: true,
        acceptTerms: true,
        businessName: businessName,
        businessRfc: businessRfc,
        keyCompany,
        clientType,
      },
    };
    setLoading(true);
    LeadsService.createLead(params).then((response) => {
      if (response.status === 201) {
        toast.success("Se ingreso la información correctamente");
        navigate(0);
      } else {
        toast.error("Ocurrió un error al actualizar la información");
      }
      setLoading(false);
    });
  };

  const updateLead = (props) => {
    const businessName =
      props?.businessName?.trim() !== "" ? props.businessName : undefined;
    const businessRfc =
      props?.businessRfc?.trim() !== "" ? props.businessRfc : undefined;
    const keyCompany = props?.keyCompany?.trim();
    const rateInfo = props?.rateInfo?.trim();
    const params = {
      ...props,
      ...{
        businessName: businessName,
        businessRfc: businessRfc,
        keyCompany,
        rateInfo,
      },
    };
    setLoading(true);
    LeadsService.updateLead(leadKey, params).then((response) => {
      if (response.status === 200) {
        toast.success("Se actualizó la información correctamente");
        navigate(0);
      } else {
        toast.error("Ocurrió un error al actualizar la información");
      }
      setLoading(false);
    });
  };
  return (
    <>
      <SlidingMenu {...props} panelTitle={panelTitle}>
        <div>
          {isNew && (
            <div>
              <div className="relative pb-4">
                <label
                  htmlFor="channel"
                  className="block mb-2 text-xs font-medium text-gray-900"
                >
                  Tipo
                </label>
                <select
                  value={leadType}
                  onChange={(e) => handleLeadType(e)}
                  className={`bg-gray-50 border border-gray-300 text-gray-900  rounded-md block w-full p-2.5`}
                  id={`leadType`}
                >
                  <option value="person">Persona</option>
                  <option value="business">Empresa</option>
                  <option value="agency">Agencia</option>
                </select>
              </div>
            </div>
          )}
          <form id="addLeadForm" onSubmit={handleSubmit(onSubmit)}>
            {isBusiness && (
              <div>
                <div className="relative pb-4">
                  <label
                    htmlFor="businessName"
                    className="block mb-2 text-xs font-medium text-gray-900"
                  >
                    Nombre de la empresa
                  </label>
                  <input
                    defaultValue={""}
                    {...register("businessName", {
                      required: "Campo requerido",
                    })}
                    type="text"
                    id="businessName"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 rounded-md block w-full p-2.5`}
                  />
                  {errors.businessName && (
                    <p
                      role="alert"
                      className="text-red-800 pt-2 text-right text-xs"
                    >
                      {errors.businessName?.message}
                    </p>
                  )}
                </div>
                <div className="relative pb-4">
                  <label
                    htmlFor="address"
                    className="block mb-2 text-xs font-medium text-gray-900"
                  >
                    Dirección
                  </label>
                  <input
                    defaultValue={""}
                    {...register("address")}
                    type="text"
                    id="address"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 rounded-md block w-full p-2.5`}
                  />
                </div>
                <div className="relative pb-4">
                  <label
                    htmlFor="businessRfc"
                    className="block mb-2 text-xs font-medium text-gray-900"
                  >
                    RFC de la empresa
                  </label>
                  <input
                    defaultValue={""}
                    {...register("businessRfc")}
                    type="text"
                    id="businessRfc"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 rounded-md block w-full p-2.5`}
                  />
                  {errors.businessRfc && (
                    <p
                      role="alert"
                      className="text-red-800 pt-2 text-right text-xs"
                    >
                      {errors.businessRfc?.message}
                    </p>
                  )}
                </div>
                <div className="relative pb-4">
                  <label
                    htmlFor="keyCompany"
                    className="block mb-2 text-xs font-medium text-gray-900"
                  >
                    Clave
                  </label>
                  <input
                    defaultValue={""}
                    {...register("keyCompany")}
                    type="text"
                    id="keyCompany"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 rounded-md block w-full p-2.5`}
                  />
                  {errors.keyCompany && (
                    <p
                      role="alert"
                      className="text-red-800 pt-2 text-right text-xs"
                    >
                      {errors.keyCompany?.message}
                    </p>
                  )}
                </div>
                <div className="relative pb-4">
                  <label
                    htmlFor="rateInfo"
                    className="block mb-2 text-xs font-medium text-gray-900"
                  >
                    Información tarifaria
                  </label>
                  <textarea
                    placeholder={""}
                    className={`bg-gray-50 border border-gray-300 text-gray-900  rounded-md block w-full p-2.5`}
                    autoComplete="off"
                    defaultValue={""}
                    {...register("rateInfo")}
                    cols="30"
                    rows="5"
                  ></textarea>
                  {errors.rateInfo && (
                    <p
                      role="alert"
                      className="text-red-800 pt-2 text-right text-xs"
                    >
                      {errors.rateInfo?.message}
                    </p>
                  )}
                </div>
              </div>
            )}
            {isNew && (
              <>
                <div className="relative pb-4">
                  <label
                    htmlFor="channel"
                    className="block mb-2 text-xs font-medium text-gray-900"
                  >
                    Canal de contacto inicial
                  </label>
                  <select
                    defaultValue={"miareefim"}
                    className={`bg-gray-50 border border-gray-300 text-gray-900  rounded-md block w-full p-2.5`}
                    id={`channel`}
                    {...register("channel")}
                  >
                    <option value="email">Correo</option>
                    <option value="phone">Teléfono</option>
                    <option value="web">WEB</option>
                    <option value="whatsapp">Whatsapp</option>
                    <option value="facebook">Facebook</option>
                    <option value="instagram">Instagram</option>
                    <option value="crm">CRM</option>
                    <option value="facetoface">Visita Presencial</option>
                    <option value="caravanevent">Caravana/Evento/Blitz</option>
                    <option value="otas">OTAS</option>
                    <option value="bodas_com_mx">bodas.com.mx</option>
                    <option value="hotel_planner">Hotel Planner</option>
                    <option value="other">Otro</option>
                  </select>
                </div>
                <div className="relative pb-4">
                  <label
                    htmlFor="property"
                    className="block mb-2 text-xs font-medium text-gray-900"
                  >
                    Propiedad
                  </label>
                  <select
                    defaultValue={"miareefim"}
                    className={`bg-gray-50 border border-gray-300 text-gray-900  rounded-md block w-full p-2.5`}
                    id={`property`}
                    {...register("property")}
                  >
                    <option value="miareefim">MÍA Reef Isla Mujeres</option>
                    <option value="miabacalar">MÍA Bacalar</option>
                    <option value="miacityvsa">MÍA City Villahermosa</option>
                    <option value="lunaazulbac">Luna Azul Bacalar</option>
                    <option value="miavacationclub">MÍA Vacation Club</option>
                    <option value="corp">MÍA Hotels And Resorts</option>
                  </select>
                </div>
              </>
            )}
            <div className="relative pb-4">
              <label
                htmlFor="name"
                className="block mb-2 text-xs font-medium text-gray-900"
              >
                Nombre
              </label>
              <input
                defaultValue={""}
                {...register("name", { required: "Campo requerido" })}
                type="text"
                id="name"
                className={`bg-gray-50 border border-gray-300 text-gray-900 rounded-md block w-full p-2.5`}
              />
              {errors.name && (
                <p
                  role="alert"
                  className="text-red-800 pt-2 text-right text-xs"
                >
                  {errors.name?.message}
                </p>
              )}
            </div>
            <div className="relative pb-4">
              <label
                htmlFor="lastName"
                className="block mb-2 text-xs font-medium text-gray-900"
              >
                Apellido
              </label>
              <input
                defaultValue={""}
                {...register("lastName", { required: "Campo requerido" })}
                type="text"
                id="lastName"
                className={`bg-gray-50 border border-gray-300 text-gray-900 rounded-md block w-full p-2.5`}
              />
              {errors.lastName && (
                <p
                  role="alert"
                  className="text-red-800 pt-2 text-right text-xs"
                >
                  {errors.lastName?.message}
                </p>
              )}
            </div>
            <div className="relative pb-4">
              <label
                htmlFor="email"
                className="block mb-2 text-xs font-medium text-gray-900"
              >
                Correo
              </label>
              <input
                id="email"
                type="email"
                className={`bg-gray-50 border border-gray-300 text-gray-900 rounded-md block w-full p-2.5`}
                autoComplete="off"
                defaultValue={email}
                {...register("email")}
              />
              {errors.email && (
                <p
                  role="alert"
                  className="text-red-800 pt-2 text-right text-xs"
                >
                  {errors.email?.message}
                </p>
              )}
            </div>
            <div className="relative pb-4">
              <label
                htmlFor="phone"
                className="block mb-2 text-xs font-medium text-gray-900"
              >
                Teléfono
              </label>
              <Controller
                control={control}
                name="phone"
                defaultValue={phone}
                render={({ field }) => (
                  <PhoneInput
                    enableAreaCodes={true}
                    value={field.value}
                    enableSearch={true}
                    country={"mx"}
                    inputClass={`!bg-gray-50 !border !h-fit !border-gray-300 !text-gray-900 !rounded-md block !w-full p-2.5 !text-[1rem]`}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
            {isNew && (
              <>
                <div className="relative pb-4">
                  <label
                    htmlFor="target"
                    className="block mb-2 text-xs font-medium text-gray-900"
                  >
                    Segmento
                  </label>
                  <select
                    defaultValue={"bookings"}
                    className={`bg-gray-50 border border-gray-300 text-gray-900  rounded-md block w-full p-2.5`}
                    id={`target`}
                    {...register("target")}
                  >
                    <option value="bookings">Reservaciones</option>
                    <option value="update_rsv">
                      Actualización de reservas
                    </option>
                    <option value="additional_services">
                      Servicios adicionales
                    </option>
                    <option value="spa_service">SPA</option>
                    <option value="mkt">Marketing</option>
                    <option value="weddings">Bodas</option>
                    <option value="groups">Grupos</option>
                    <option value="events">Eventos</option>
                    <option value="agreement">Convenio</option>
                    <option value="agency">Agencias</option>
                    <option value="referralprogram">Referidos Club</option>
                    <option value="other">Otro</option>
                  </select>
                </div>
                <div className="relative pb-4">
                  <label
                    htmlFor="message"
                    className="block mb-2 text-xs font-medium text-gray-900"
                  >
                    Solicitud Inicial
                  </label>
                  <textarea
                    placeholder={"Mensaje"}
                    className={`bg-gray-50 border border-gray-300 text-gray-900  rounded-md block w-full p-2.5`}
                    autoComplete="off"
                    defaultValue={""}
                    {...register("message")}
                    cols="30"
                    rows="5"
                  ></textarea>
                  {errors.message && (
                    <p
                      role="alert"
                      className="text-red-800 pt-2 text-right text-xs"
                    >
                      {errors.message?.message}
                    </p>
                  )}
                </div>
              </>
            )}
            <div className="py-4 relative">
              <button
                type="submit"
                className={`w-full p-3 rounded-lg bg-indigo-700 hover:bg-indigo-500 text-white ${
                  loading ? "loading-state" : ""
                }`}
              >
                {loading ? <DotsLoading /> : <strong>Guardar</strong>}
              </button>
            </div>
          </form>
        </div>
      </SlidingMenu>
    </>
  );
}
