import Selected from "./Selected"
import { FaCheck, FaChevronDown } from "react-icons/fa"
import { MdFilterAltOff } from "react-icons/md"
export default function SelectButton(props) {
    const { selectedOptions, showSelector, handleShowSelector, handleClearSelectOption } = props
    return (
        <div className="w-full flex flex-row justify-center items-center gap-2">
            <Selected selectedOptions={selectedOptions.length} />
            <button
                className="w-full flex justify-between flex-row items-center gap-2 p-2 rounded-lg hover:bg-slate-100"
                onClick={(e) => handleShowSelector()}
            >
                <span className="font-bold text-sm">
                    Seleccionados
                </span>
                <FaChevronDown className={`transition-all ${showSelector ? ' rotate-180' : ''}`} />
            </button>
            <button
                onClick={(e) => handleClearSelectOption()}
                className="ml-4 p-2 hover:bg-slate-100 rounded-md"
            >
                <MdFilterAltOff />
            </button>
        </div>
    )
}