
import AuthService from "../services/auth.service"
const useAuth = () => {
    const userObj = AuthService.getCurrentUser();
    const appsObj = AuthService.getCurrentUserPermissions()
    let isAdmin = false
    let apps = appsObj.apps
    let access = []
    let userInfo = {
        name: userObj.name,
        lastName: userObj.lastName
    }
    if(apps.length > 0){
        isAdmin = apps.some((app) => app.code === 'admin')
        access = apps.map(app => app.code)
    }
    return { user: userInfo, apps: apps, access: access, isAdmin }
}

export default useAuth
