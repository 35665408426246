import { MdArrowBack } from "react-icons/md"
import { useNavigate } from "react-router-dom"
export default function ArrowBack(props) {
    const { navigateTo = 0 } = props
    const navigate = useNavigate()
    const handleBack = () => {
        navigate(navigateTo)
    }
    return (
        <div className="pb-2">
            <button
                onClick={() => handleBack()}
                className="text-sm flex items-center gap-2 text-indigo-600">
                <MdArrowBack className="w-4 h-4" /> Regresar
            </button>
        </div>
    )
}