import axios from "./axios_interceptor"
const API_URL = process.env.REACT_APP_API_URL

const createLead = (data) => {
    return axios.post(`${API_URL}/crm/leads`, data)
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

const updateLead = (key, data) => {
    return axios.put(`${API_URL}/crm/lead/${key}`, data)
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

const getLeads = ({ params = false } = { }) => {
    return axios.get(`${API_URL}/crm/leads`, { ...(params && { params }) })
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

const getLead = (leadid) => {
    return axios.get(`${API_URL}/crm/leads/${leadid}`)
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

const getLeadsUsers = () => {
    return axios.get(`${API_URL}/crm/leads/users`)
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

const getSegments = () => {
    return axios.get(`${API_URL}/crm/segments`)
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

const reasign = (data) => {
    return axios.post(`${API_URL}/crm/leads/reasign`, data)
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

const getStatusOptions = () => {
    return axios.get(`${API_URL}/crm/status-options`)
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

const getStatusOptionsByRequest = async (reqid) => {
    return axios
        .get(`${API_URL}/crm/leads/status-history/${reqid}`)
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

const updateRequestStatus = async (params) => {
    return axios
        .put(`${API_URL}/crm/leads/status`, params)
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

const createNote = async (params) => {
    return axios
        .post(`${API_URL}/crm/leads/notes`, params)
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

const getChannels = () => {
    return axios.get(`${API_URL}/crm/channels`)
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

const uploadContractFile = (key, formData) => {
    return axios
        .postForm(`${API_URL}/crm/lead/${key}/contract`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error.response)
            return false
        })
}

const downloadContractFile = (key) => {
    return axios.get(`${API_URL}/crm/lead/contract/download/${key}`, {
        responseType: 'arraybuffer'
    })
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

const getLeadContractFile = async (key) => {
    return axios.get(`${API_URL}/crm/lead/${key}/contract`)
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

export default {
    createLead,
    getLeads,
    getLead,
    getLeadsUsers,
    getSegments,
    reasign,
    getStatusOptions,
    getStatusOptionsByRequest,
    updateRequestStatus,
    createNote,
    getChannels,
    updateLead,
    uploadContractFile,
    downloadContractFile,
    getLeadContractFile
}