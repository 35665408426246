import SlidingMenu from "../../SlidingMenu"
import Select from "react-select"
import { useForm, Controller } from "react-hook-form"
import { useState } from "react"
import DotsLoading from "../../DotsLoading"
import LeadsService from "../../../services/leads.service"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
export default function ReAssign(props) {
    const { data = [], showSlide, segments = [], clientRequestId = 0 } = props
    const navigate = useNavigate()
    const { control, register, handleSubmit, formState: { errors }, reset } = useForm()
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    let usersOptions = []
    let segmentsOptions = []
    if (data.length > 0) {
        data.map((item) => {
            usersOptions.push({
                value: item.id,
                label: `${item.name} ${item.lastName} - [${item.email}]`
            })
        })
    }
    if (segments.length > 0) {
        segments.map((item) => {
            segmentsOptions.push({
                value: item.id,
                label: item.description
            })
        })
    }
    const submit = (props) => {
        let leadsUsers = props.leadsUsers.map(function (user) {
            return user.value
        })
        let target = props.target?.value ?? undefined
        let params = {
            leadsUsers,
            target,
            clientRequestId
        }
        setLoadingSubmit(true)
        LeadsService.reasign(params)
            .then(response => {
                if (response.status === 200) {
                    toast.success("Se actualizó la información correctamente")
                    navigate("/leads")
                }
                else {
                    toast.error("Ocurrió un error al actualizar la información")
                }
                setLoadingSubmit(false)
            })
    }
    return (
        <>
            <SlidingMenu {...props} panelTitle={"Reasignar lead"}>
                <form id="reasignForm" onSubmit={handleSubmit(submit)}>
                    <div className="relative pb-4 hidden">
                        <label className="inline-flex relative items-center cursor-pointer">
                            <input type="checkbox" value="" className="sr-only peer" />
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                            <span className="ml-3 text-sm font-medium text-gray-900">Toggle me</span>
                        </label>
                    </div>
                    <div className="relative pb-4">
                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Usuario</label>
                        <Controller
                            name="leadsUsers"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) =>
                                <Select
                                    className="select"
                                    isMulti
                                    isLoading={usersOptions.length === 0}
                                    options={usersOptions}
                                    isSearchable={false}
                                    {...field}
                                />
                            }
                        />

                    </div>
                    <div className="relative pb-4">
                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Segmento</label>
                        <Controller
                            name="target"
                            control={control}
                            rules={{
                                required: false,
                            }}
                            render={({ field }) =>
                                <Select
                                    className="select"
                                    isLoading={segmentsOptions.length === 0}
                                    options={segmentsOptions}
                                    isSearchable={false}
                                    {...field}
                                />
                            }
                        />
                    </div>
                    <div className="relative py-4 flex justify-end">
                        <button type="submit" className={`w-full p-3 rounded-lg bg-indigo-700 hover:bg-indigo-500 text-white ${loadingSubmit ? 'loading-state' : ''}`}>
                            {
                                loadingSubmit ?
                                    <DotsLoading />
                                    :
                                    <strong>Actualizar</strong>
                            }
                        </button>
                    </div>
                </form>
            </SlidingMenu>
        </>
    )
}