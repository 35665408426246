import CheckSelected from "./CheckSelected"
export default function SelectOptions(props) {
    const { selectorKey, options, showSelector, handleSelectOption} = props
    return (
        <div
            className={` transition-all ${showSelector ? 'opacity-1 translate-y-0' : 'h-0 opacity-0 -translate-y-6'} z-[98] w-full absolute mt-4 bg-white drop-shadow-lg border rounded-lg overflow-hidden`}>
            <ul>
                {
                    options.map((item, index) => {
                        return (
                            <ul
                                key={`${selectorKey}_option_${index}`}
                                className="flex flex-row items-center gap-2 p-2 hover:bg-indigo-100 cursor-pointer text-sm"
                                onClick={() => handleSelectOption(item)}
                            >
                                <CheckSelected selected={item.selected} />
                                {item.value}
                            </ul>
                        )
                    })
                }
            </ul>
        </div>
    )
}