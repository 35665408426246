import Badge from "../../Badge"
import { MdOutlineSensors } from "react-icons/md"
import { format } from "date-fns"
import AddNote from "./AddNote"
import { useState } from "react"
import LeadsService from "../../../../services/leads.service"
import TrackingHistory from "./TrackingHistory"
import AssignedUsers from "../AssignedUsers"
export default function LeadTracking(props) {
    const { notes = null, setNotes, assigned, users } = props
    const [showAddNote, setShowAddNote] = useState(false)
    const [channels, setChannels] = useState([])
    const handleAddNote = () => {
        setShowAddNote(true)
        getChannels()
    }

    const manageNotes = (note) => {

        setNotes([note, ...notes])
    }
    //Obtenemos los canales de contacto para seguimiento
    const getChannels = () => {
        try {
            LeadsService.getChannels()
                .then(response => {
                    if (response.status === 200) {
                        setChannels(response.data.data.channels)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div className="bg-white drop-shadow-lg rounded-lg w-full">
                <div className="p-8">
                    <div className="w-full flex md:justify-between gap-4">
                        <div className="pt-4 flex font-bold">
                            <AssignedUsers assigned={assigned} users={users} />
                        </div>
                        <button
                            onClick={(e) => handleAddNote()}
                            className="w-fit px-4 py-2 rounded-lg bg-indigo-700 hover:bg-indigo-500 text-white flex items-center justify-center  gap-2 font-bold"
                        >
                            Seguimiento <MdOutlineSensors className="w-6 h-6" />
                        </button>
                    </div>
                    <div className="notes-container pt-8 flex flex-col gap-4">
                        <TrackingHistory tracking={notes} />
                    </div>
                </div>
            </div>
            <AddNote
                channels={channels}
                manageNotes={manageNotes}
                showSlide={showAddNote}
                setShowSlide={setShowAddNote}
                {...props}
            />
        </>
    )
}