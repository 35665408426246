import { Link, useLocation } from "react-router-dom"
import AuthService from "../../../services/auth.service"
import { IoExitOutline } from "react-icons/io5"
import NavLink from "./NavLink"
import verifyAccess from "../../../auth/verifyAccess"
import MenuItems from "./MenuItems"
import { useParams } from "react-router-dom"
export default function Navigation(props) {
    let location = useLocation()
    const logOut = () => {
        AuthService.logout();
    }
    const isActive = (target, lavel = 0) => {
        let arrayUrl = location.pathname.replace("/", "").split("/")
        let path = arrayUrl[lavel]
        return target === path
    }
    
    return (
        <nav className="sticky top-0 h-[100vh] border border-r-1 border-slate-200 bg-white min-w-[250px] w-[14rem]">
            <div className="w-full h-[5rem] flex justify-center items-center pt-8 relative p-4 border border-r-1 border-slate-200">
                <Link to="/">
                    <img src="/files/logo-hotelesmia.png" alt="" width={80} height={70} className="" />
                </Link>
            </div>
            <ul className="p-4">
                <li>
                    <NavLink to="/" isActive={isActive('')} label={"Dashboard"} />
                </li>
                <li className="py-2 hidden">
                    <Link to="/properties" className={`w-full flex rounded-lg hover:bg-slate-100 ${isActive('properties') ? 'bg-indigo-100 border-2 border-indigo-500 font-bold text-indigo-600' : ''}`}>
                        <span className="p-2 w-full">
                            Propiedades
                        </span>
                    </Link>
                </li>
                {
                    verifyAccess({ app: 'reservas_servicios', allowedRoles: ['servicios_rsv_access'] }) &&
                    <MenuItems label={'Reservas Servicios'} target={'services-reservation'}>
                        <ul className={`pl-5 mt-2 space-y-1 list-inside`}>
                            {
                                verifyAccess({ app: 'reservas_servicios', allowedRoles: ['hunab_ku_service'] }) &&
                                <li>
                                    <Link to="/services-reservation/hunab_ku_rest" className={`w-full flex rounded-lg hover:bg-slate-100 ${isActive('hunab_ku_rest', 1) ? 'font-bold text-indigo-600' : ''}`}>
                                        <span className="p-2 w-full">
                                            Hunab Ku
                                        </span>
                                    </Link>
                                </li>
                            }
                            {
                                verifyAccess({ app: 'reservas_servicios', allowedRoles: ['ixchel_spa_service'] }) &&
                                <li>
                                    <Link to="/services-reservation/ixchel-spa" className={`w-full flex rounded-lg hover:bg-slate-100 ${isActive('ixchel-spa', 1) ? 'font-bold text-indigo-600' : ''}`}>
                                        <span className="p-2 w-full">
                                            Ixchel SPA
                                        </span>
                                    </Link>
                                </li>
                            }
                        </ul>
                    </MenuItems>
                }
                {
                    verifyAccess({ app: 'crm', allowedRoles: ['crm-access'] }) &&
                    <li>
                        <Link to="/leads" className={`w-full flex rounded-lg hover:bg-slate-100 ${isActive('leads') ? 'bg-indigo-100 border-2 border-indigo-500 font-bold text-indigo-600' : ''}`}>
                            <span className="p-2 w-full">
                                Seguimientos
                            </span>
                        </Link>
                    </li>
                }
                <li>
                    <a href="/login" onClick={logOut} className="w-full flex rounded-lg hover:bg-slate-100">
                        <span className="p-2 w-full flex items-center justify-between">
                            Cerrar sesión <IoExitOutline className="w-5 h-5" />
                        </span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}