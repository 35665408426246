import { useState, useEffect } from "react"
import { FaCheck, FaChevronDown } from "react-icons/fa"
import CheckSelected from "./CheckSelected"
import Selected from "./Selected"
import SelectButton from "./SelectButton"
import SelectOptions from "./SelectOptions"
import DateSelect from "./DateSelect"
import { useOutsideClick } from "../../../custom_hooks/useOutsideClick"
export default function FilterSelector(props) {
    const { config, setOptions, selectorSelected, setSelectorSelected } = props
    const { selectorKey, label, options = [], type = "text", selectedOptions = [], selectorFocus } = config
    //type can be, input text, select, rangedate
    const [selectedValues, setSelectedValues] = useState([])
    const [showSelector, setShowSelector] = useState(false)
    const handleSelectOption = (selected) => {
        options.map((item) => {
            return item.key === selected.key ? item.selected = !item.selected : false
        })
        const selectedOptions = options.filter((item) => item.selected)
        setOptions({ ...config, options: options, selectedOptions: selectedOptions })
    }

    const handleClearSelectOption = () => {
        options.map((item) => {
            return item.selected = false
        })
        const selectedOptions = options.filter((item) => item.selected)
        setOptions({ ...config, options: options, selectedOptions: selectedOptions })
    }

    const handleSelectDate = (start, end) => {
        setOptions({ ...config, start, end })
    }

    const handleShowSelector = () => {
        setSelectorSelected(selectorKey)
        setShowSelector(!showSelector)
    }

    useEffect(() => {
        if (selectorSelected !== selectorKey) {
            setShowSelector(false)
        }
    }, [selectorSelected])

    const outSideClickRef = useOutsideClick(() => {
        setShowSelector(false)
    });
    return (
        <div className="relative" ref={outSideClickRef}>
            <div className="flex items-center w-full gap-4 border p-2 rounded-lg bg-white drop-shadow-md">
                <div className="w-fit">
                    <span className="whitespace-nowrap">{label}:</span>
                </div>
                {
                    type === "select" &&
                    <SelectButton
                        selectedOptions={selectedOptions}
                        showSelector={showSelector}
                        handleShowSelector={handleShowSelector}
                        handleClearSelectOption={handleClearSelectOption}
                    />
                }
                {
                    type === "date" &&
                    <DateSelect
                        startDate={config.start}
                        endDate={config.end}
                        handleSelectDate={handleSelectDate}
                    />
                }
            </div>
            {
                type === "select" &&
                <SelectOptions
                    selectorKey={selectorKey}
                    options={options}
                    showSelector={showSelector}
                    handleSelectOption={handleSelectOption}
                />
            }

        </div>
    )
}