export const filterDataConfig = () => {
    const config = {
        "properties": [
            { "key": "miareefim", "value": "MÍA Reef Isla Mujeres", "selected": false },
            { "key": "miabacalar", "value": "MÍA Bacalar", "selected": false },
            { "key": "miacityvsa", "value": "MÍA City Villahermosa", "selected": false },
            { "key": "corp", "value": "MÍA Hotels And Resorts", "selected": false },
            { "key": "lunaazulbac", "value": "Luna Azul Bacalar", "selected": false },
            { "key": "miavacationclub", "value": "MÍA Vacation Club", "selected": false }
        ],
        "segments": [
            {
                "id": 1,
                "key": "contact",
                "value": "Contacto",
                "selected": false
            },
            {
                "id": 2,
                "key": "weddings",
                "value": "Bodas",
                "selected": false
            },
            {
                "id": 3,
                "key": "groups",
                "value": "Grupos",
                "selected": false
            },
            {
                "id": 4,
                "key": "events",
                "value": "Eventos",
                "selected": false
            },
            {
                "id": 5,
                "key": "other",
                "value": "Otro",
                "selected": false
            },
            {
                "id": 9,
                "key": "mkt",
                "value": "Marketing",
                "selected": false
            },
            {
                "id": 10,
                "key": "bookings",
                "value": "Reservas",
                "selected": false
            },
            {
                "id": 11,
                "key": "update_rsv",
                "value": "Cambios o aclaración de reservas",
                "selected": false
            },
            {
                "id": 12,
                "key": "additional_services",
                "value": "Servicios Adicionales",
                "selected": false
            },
            {
                "id": 13,
                "key": "spa_service",
                "value": "SPA",
                "selected": false
            },
            {
                "id": 14,
                "key": "agreement",
                "value": "Convenio",
                "selected": false
            },
            {
                "id": 15,
                "key": "agency",
                "value": "Agencia",
                "selected": false
            },
            {
                "id": 16,
                "key": "referralprogram",
                "value": "Referidos Club",
                "selected": false
            }
        ],
        "status": [
            {
                "id": 1,
                "key": "new",
                "value": "Nuevo",
                "selected": false
            },
            {
                "id": 2,
                "key": "pending",
                "value": "Pendiente",
                "selected": false
            },
            {
                "id": 3,
                "key": "confirmed",
                "value": "Confirmado",
                "selected": false
            },
            {
                "id": 4,
                "key": "inprocess",
                "value": "En Proceso",
                "selected": false
            },
            {
                "id": 5,
                "key": "finished",
                "value": "Finalizado",
                "selected": false
            },
            {
                "id": 6,
                "key": "cancelled",
                "value": "Cancelado",
                "selected": false
            },
            {
                "id": 7,
                "key": "noshow",
                "value": "No Show",
                "selected": false
            },
            {
                "id": 8,
                "key": "reassigned",
                "value": "Reasignado",
                "selected": false
            },
            {
                "id": 9,
                "key": "unanswered",
                "value": "Sin Respuesta",
                "selected": false
            },
            {
                "id": 10,
                "key": "duplicated",
                "value": "Duplicado",
                "selected": false
            }
        ]
    }

    return config
}