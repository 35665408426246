import { FaTimes } from "react-icons/fa";
import { createPortal } from "react-dom";
import { useEffect, useState } from "react";
import ModalProvider from "../../provider/ModalProvider";
import Backdrop from "./Backdrop";
import Close from "./Close";
export default function Modal(props) {
  const {
    showModal = false,
    setShowModal,
    disableClose = false,
    title = "",
    description = "",
    showCancelOption = true,
    cancelLbl = "Cancelar",
    continueLbl = "OK",
    handler = () => {},
  } = props;
  const [generate, setGenerate] = useState(false);
  const [showBack, setShowBack] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [action, setAction] = useState(undefined);
  useEffect(() => {
    if (showModal) {
      document.documentElement.style.overflow = "hidden";
      setGenerate(showModal);
    }
    if (!showModal) {
      document.documentElement.style.overflow = "";
      setTimeout(() => {
        setGenerate(false);
      }, 300);
    }
    const timerBack = setTimeout(() => {
      setShowBack(showModal);
    }, 100);
    const timer = setTimeout(() => {
      setShowContent(showModal);
    }, 300);

    return () => {
      clearTimeout(timer);
      clearTimeout(timerBack);
    };
  }, [showModal]);
  return (
    <ModalProvider setShowModal={setShowModal}>
      {generate &&
        createPortal(
          <div
            className={`z-[99] fixed inset-0 w-full h-full bg-black bg-opacity-50 transition-all ${
              showBack ? "opacity-1" : "opacity-0"
            }`}
          >
            <div
              className={`py-4 transition-all ${
                showContent ? "opacity-1 scale-100" : "opacity-0 scale-90"
              } fixed w-full md:w-[28rem] 2xl:w-[32rem] max-w-full --h-full md:h-[100vh] 2xl:max-h-[90vh] top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]`}
            >
              <div className="flex flex-col rounded-lg bg-white overflow-hidden h-full">
                <div className="w-full p-8 h-fit bg-indigo-700">
                  {!disableClose && (
                    <div className="pt-8 pr-8 right-0 absolute top-0">
                      <button
                        onClick={() => setShowModal(false)}
                        className="transition-all text-white  hover:text-indigo-400 rounded"
                      >
                        <FaTimes className="w-5 h-5" />
                      </button>
                    </div>
                  )}
                  <div className="mt-4 mr-4 text-white text-center">
                    <p className="text-lg font-bold">{title}</p>
                    {description && (
                      <p className="text-sm mt-2">{description}</p>
                    )}
                  </div>
                </div>
                <div className="bg-white h-full w-full p-8 overflow-hidden">
                  <div className="h-full overflow-y-auto">{props.children}</div>
                </div>
              </div>
            </div>
          </div>,
          document.body
        )}
    </ModalProvider>
  );
}
