import InstantForm from "./InstantForm";

export default function RequestContent(props) {
  const { lead } = props;
  const {
    content,
    customContent = "",
    contentType,
  } = lead?.clientRequest ?? {};
  const jsonContent = customContent !== "" ? typeof customContent === "object" ? customContent : JSON.parse(customContent) : false;
  return (
    <div>
      {lead.message != "" && (
        <>
          <p className="mb-2 font-bold">Solicitud inicial: </p>
          <div className="border bg-indigo-50 p-4 rounded-md whitespace-pre-line">
            <p className="italic">{lead.message}</p>
          </div>
        </>
      )}
      {(contentType === "meta_instant_form" && jsonContent) && <InstantForm {...jsonContent} />}
    </div>
  );
}
