export default function InstantForm(props) {
  const {id, form_id, adset_name, field_data } = props;
  return (
    <div>
      <p className="font-bold pb-4 text-sm">Respuestas del formulario:</p>
      <ul className="pb-4">
        {field_data.map((item, itemIndex) => {
          return (
            <li key={`form_ans_${itemIndex}`} className="pb-2">
              <p className="text-sm">{item.question_label}</p>
              <span className="text-sm font-bold">{item.answer}</span>
            </li>
          );
        })}
      </ul>
      <div className="text-xs">
        <p className="font-bold pb-2">META Clientes potenciales</p>
        <p>Lead ID: <span className="font-bold">{id}</span></p>
        <p>Form ID: <span className="font-bold">{form_id}</span></p>
      </div>
    </div>
  );
}
