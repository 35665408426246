import AuthService from "../services/auth.service"
import UseAuth from "./useAuth"
export default function verifyAccess(props) {
    const  { app = "", allowedRoles = []} = props
    //Verify by APP
    const { access, isAdmin, apps } = UseAuth()
    if (AuthService.isLoggedIn() && allowedRoles && (apps.length > 0)) {
        //Obtenemos los roles de la app
        const appData = apps.filter(item => item.code === app)[0]
        return appData?.roles.some(role => (allowedRoles.includes(role.code))) || isAdmin
    }
    return false
}