import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Container from "../Container";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import LeadsService from "../../../services/leads.service";
import {
  FaEnvelope,
  FaPhoneAlt,
  FaEdit,
  FaExchangeAlt,
  FaTasks,
} from "react-icons/fa";
import {
  MdBusiness,
  MdPerson,
  MdPhone,
  MdEmail,
  MdMap,
  MdEdit,
  MdUploadFile,
} from "react-icons/md";
import Badge from "../Badge";
import StatusBadge from "../StatusBadge";
import ReAssign from "./ReAssign";
import LeadStatus from "./LeadStatus";
import LeadTracking from "./Tracking/LeadTracking";
import { format } from "date-fns";
import ArrowBack from "../../ArrowBack";
import LeadForm from "./LeadForm";
import LeadContract from "./LeadContract";
import AssignedUsers from "./AssignedUsers";
import TabAction from "../../Utils/TabAction";
import TabContent from "../../Utils/TabContent";
import FilesTab from "./Files/FilesTab";
import RequestContent from "./LeadDetail/RequestContent";
export default function LeadDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isNew, setIsNew] = useState(false);
  const [lead, setLead] = useState({});
  const [usersApp, setUsersApp] = useState([]);
  const [usersAssigned, setUsersAssigned] = useState([]);
  const [showReAssign, setShowReAssign] = useState(false);
  const [showSlideStatus, setShowSlideStatus] = useState(false);
  const [leadsUsers, setLeadsUsers] = useState([]);
  const [segments, setSegments] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [reqStatusHistory, setReqStatusHistory] = useState([]);
  const [notes, setNotes] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showSlideContract, setShowSlideContract] = useState(false);
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (id === undefined) {
        setIsNew(true);
      } else {
        LeadsService.getLead(id).then((response) => {
          if (response.status === 200) {
            const { lead, users } = response.data.data;
            setUsersApp(users);
            setUsersAssigned(lead?.clientRequest?.usersClientRequests);
            setLead(lead);
            setNotes(lead?.clientRequest?.clientRequestNotes);
          }
        });
      }
    }
    return () => (isSubscribed = false);
  }, []);

  const handleReAssign = (item) => {
    //setReservationSelected(item)
    getLeadsUsers();
    getSegments();
    setShowReAssign(true);
  };

  const handleLeadStatus = (item) => {
    getReqStatusHistory();
    getStatusOptions();
    setShowSlideStatus(true);
  };

  const getLeadsUsers = () => {
    try {
      LeadsService.getLeadsUsers().then((response) => {
        if (response.status === 200) {
          setLeadsUsers(response.data.data.users);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getSegments = () => {
    try {
      LeadsService.getSegments().then((response) => {
        if (response.status === 200) {
          setSegments(response.data.data.segments);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusOptions = () => {
    try {
      LeadsService.getStatusOptions().then((response) => {
        if (response.status === 200) {
          setStatusOptions(response.data.data.statusOptions);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getReqStatusHistory = () => {
    try {
      LeadsService.getStatusOptionsByRequest(lead?.clientRequest?.id).then(
        (response) => {
          if (response.status === 200) {
            setReqStatusHistory(response.data.data.statusOptions);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeStatusResponse = (response) => {
    try {
      let currentLead = lead;
      currentLead["clientRequest"]["statusOptions"] = response;
      setLead(currentLead);
    } catch (error) {
      return false;
    }
  };

  const [tabSelected, setTabSelected] = useState("default");
  const handleTabSelected = (option) => {
    setTabSelected(option);
  };

  return (
    <>
      <Container>
        <div>
          <ArrowBack navigateTo={"/leads"} />
          <div className="flex items-center w-full justify-between">
            <h1 className="font-bold text-2xl">Cliente</h1>
          </div>
          <div className="pt-2">
            {isNew ? <p>Crear</p> : <p>Detalle solicitud</p>}
          </div>
        </div>
        <div className="pt-8 w-full max-w-screen-xl flex flex-col gap-4">
          <div className="bg-white shadow-lg w-full rounded-lg h-fit">
            <div>
              <div className="bg-indigo-700 text-white rounded-t-md drop-shadow-md">
                <div className="relative p-4 flex flex-col gap-1">
                  {lead.clientCompany && (
                    <div className="flex flex-row items-center gap-2">
                      <MdBusiness />
                      <p className="text-xl">
                        <strong>
                          {lead.clientCompany.name}{" "}
                          {lead.clientCompany.keyCompany && (
                            <>
                              |{" "}
                              <span className="text-xs">
                                {lead.clientCompany.keyCompany}
                              </span>
                            </>
                          )}{" "}
                          |{" "}
                          <span className="text-xs">
                            {lead.clientCompany.rfc}
                          </span>
                        </strong>
                      </p>
                    </div>
                  )}
                  <div className="flex flex-row items-center gap-2">
                    <MdPerson />
                    <p className="text-lg">
                      <strong>
                        {lead.name} {lead.lastName}
                      </strong>
                    </p>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <MdEmail /> <p>{lead.email}</p>
                  </div>
                  {lead.phone && (
                    <div className="flex flex-row items-center gap-2">
                      <MdPhone /> <p>{lead.phone}</p>
                    </div>
                  )}
                  {lead.address && (
                    <div className="flex flex-row items-center gap-2">
                      <MdMap /> <p>{lead.address}</p>
                    </div>
                  )}
                  <div className="absolute right-0 top-0 p-4">
                    <button
                      className="hover:bg-indigo-800 p-2 rounded-md"
                      onClick={(e) => setShowModalEdit(true)}
                    >
                      <MdEdit className="w-5 h-5" />
                    </button>
                  </div>
                </div>
                <div className="px-8 py-2 flex justify-between border-t border-indigo-500">
                  <div className="flex gap-4">
                    <TabAction
                      handleTabSelected={handleTabSelected}
                      optionKey={"default"}
                      tabSelected={tabSelected}
                      label={"Detalle"}
                    />
                    <TabAction
                      handleTabSelected={handleTabSelected}
                      optionKey={"files"}
                      tabSelected={tabSelected}
                      label={"Documentos"}
                    />
                  </div>
                </div>
              </div>
              <div className="p-8">
                <div>
                  <TabContent optionKey={"default"} tabSelected={tabSelected}>
                    <div>
                      <div className="pb-4 flex gap-2">
                        {lead.createdAt !== undefined ? (
                          <Badge
                            label={format(
                              new Date(lead.createdAt),
                              "dd-MM-yyyy"
                            )}
                          />
                        ) : (
                          ""
                        )}
                        <StatusBadge
                          label={
                            lead?.clientRequest?.statusOptions[0]?.description
                          }
                          status={lead?.clientRequest?.statusOptions[0]?.code}
                        />
                        <Badge
                          label={lead?.clientRequest?.property?.description}
                        />
                        <Badge
                          label={lead?.clientRequest?.targets[0]?.description}
                        />
                        <Badge
                          label={lead?.clientRequest?.channels[0]?.description}
                        />
                        <button className="hover:text-indigo-500 hidden">
                          <FaEdit />
                        </button>
                      </div>
                      {lead.clientCompany && (
                        <div className="pt-4">
                          <p className="mb-2 font-bold">
                            Información tarifaria:{" "}
                          </p>
                          <div className="border bg-indigo-50 p-4 rounded-md whitespace-pre-line">
                            <p className="italic">
                              {lead.clientCompany.rateInfo}
                            </p>
                          </div>
                        </div>
                      )}
                      <div className="pt-4 pb-8">
                        <RequestContent lead={lead} />
                      </div>
                      <div className="w-full flex gap-4 justify-end">
                        <button
                          onClick={() => handleReAssign()}
                          className="p-2 rounded-lg bg-indigo-700 hover:bg-indigo-500 text-white flex items-center gap-2 font-bold"
                        >
                          Reasignar <FaExchangeAlt />
                        </button>
                        <button
                          onClick={() => handleLeadStatus()}
                          className="p-2 rounded-lg bg-indigo-700 hover:bg-indigo-500 text-white flex items-center gap-2 font-bold"
                        >
                          Estatus <FaTasks />
                        </button>
                        {lead.clientCompany && (
                          <button
                            onClick={() => setShowSlideContract(true)}
                            className="p-2 rounded-lg bg-indigo-700 hover:bg-indigo-500 text-white flex items-center gap-2 font-bold"
                          >
                            Convenio <MdUploadFile />
                          </button>
                        )}
                      </div>
                    </div>
                  </TabContent>
                  <TabContent optionKey={"files"} tabSelected={tabSelected}>
                    <FilesTab
                      optionKey={"files"}
                      tabSelected={tabSelected}
                      client={lead.uuid}
                    />
                  </TabContent>
                </div>
                <div className="pt-4 flex justify-end">
                  <span className="text-xs font-bold">UUID: {lead.uuid}</span>
                </div>
              </div>
            </div>
          </div>
          <LeadTracking
            assigned={usersAssigned}
            users={usersApp}
            clientRequestId={lead?.clientRequest?.id}
            notes={notes}
            setNotes={setNotes}
          />
        </div>
        <ReAssign
          clientRequestId={lead?.clientRequest?.id}
          showSlide={showReAssign}
          setShowSlide={setShowReAssign}
          data={leadsUsers}
          segments={segments}
        />
        <LeadStatus
          clientRequestId={lead?.clientRequest?.id}
          showSlide={showSlideStatus}
          setShowSlide={setShowSlideStatus}
          statusList={statusOptions}
          statusHistory={reqStatusHistory}
          handleChangeStatusResponse={handleChangeStatusResponse}
        />
      </Container>
      <LeadForm
        panelTitle="Actualizar seguimiento"
        showSlide={showModalEdit}
        setShowSlide={setShowModalEdit}
        isNew={false}
        lead={lead}
        leadKey={lead.uuid}
      />
      <LeadContract
        client={lead.uuid}
        panelTitle="Convenio"
        showSlide={showSlideContract}
        setShowSlide={setShowSlideContract}
      />
    </>
  );
}
