import Navigation from "./Navigation/Navigation"
export default function Container(props) {
    return (
        <div className="flex flex-row">
            <Navigation></Navigation>
            <div className="flex-1 basis-[0%] overflow-auto">
                <main>
                    <div className="pt-8 pb-16 px-8">
                        {props.children}
                    </div>
                </main>
            </div>
        </div>
    )
}