import SlidingMenu from "../../SlidingMenu"
import Modal from "../../Modal"
import { useEffect, useState } from "react"
import FilePreview from "../FileManager/FilePreview"
import LeadsService from "../../../services/leads.service"
import NewFileForm from "../FileManager/NewFileForm"
import { MdUpgrade, MdUploadFile } from "react-icons/md"
export default function LeadContract(props) {
    const { client = 0, showSlide } = props
    const [showModalFile, setShowModalFile] = useState(false)
    const [showFilePreview, setShowFilePreview] = useState(false)
    const [file, setFile] = useState({})
    useEffect(() => {
        if (showSlide) {
            getLeadContractFile(client)
        }
    }, [showSlide])

    const getLeadContractFile = () => {
        try {
            LeadsService.getLeadContractFile(client).then(response => {
                if (response.status === 200) {
                    const { file } = response?.data?.data
                    if(file !== null){
                        setFile(file)
                    }
                }
            })
        } catch (error) {
            return {}
        }
    }

    const handleContractFileUpload = (res) => {
        const { masterFile } = res
        setFile(masterFile)
    }
    return (
        <>
            <SlidingMenu {...props}>
                <div className="pb-32">
                    <FilePreview
                        directory={{}}
                        masterFile={file}
                        handleClose={setShowFilePreview}
                    />
                </div>
                <div className="fixed bottom-0 left-0 right-0 bg-white drop-shadow-md p-8">
                    <button
                        onClick={() => setShowModalFile(true)}
                        type="button"
                        className="bg-indigo-700 hover:bg-indigo-500 w-full rounded-md text-white py-2 font-bold flex items-center justify-center gap-2"
                    >
                        {
                            file?.name ?
                                <><MdUpgrade className="w-5 h-5" /> Actualizar convenio</>
                                :
                                <><MdUploadFile className="w-5 h-5" /> Subir convenio</>
                        }
                    </button>
                </div>
            </SlidingMenu>
            <NewFileForm
                target={client}
                modalName="Convenio"
                formKey="leadContractForm"
                showModal={showModalFile}
                setShowModal={setShowModalFile}
                fetcher={LeadsService.uploadContractFile}
                responseHandler={handleContractFileUpload}
            />
        </>
    )
}