export default function SlidingMenu(props) {
    const { showSlide, setShowSlide, panelTitle } = props
    return (
        <>
            {showSlide &&
                <div className={`sliding-menu-right-master ${showSlide ? 'show' : ''} absolute inset-0 z-[99]`} aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
                    <div className={`fixed inset-0 bg-black opacity-50 transition-opacity ${showSlide ? 'opacity-1' : 'opacity-0'}`}></div>
                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className={`sliding-menu-right ${showSlide ? 'show' : ''}  pointer-events-none fixed inset-y-0 right-0 flex max-w-full`}>
                                <div className="pointer-events-auto relative w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white pb-6 shadow-xl">
                                        <div className="px-4 py-8 sm:px-6 bg-indigo-700 flex justify-between">
                                            <div>
                                                <h2 className="text-xl font-medium text-white" id="slide-over-title">
                                                    {panelTitle}
                                                </h2>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() => setShowSlide(false)}
                                                    type="button"
                                                    className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                                    <span className="sr-only">Close panel</span>
                                                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <div className="absolute inset-0 px-4 sm:px-6">
                                                <div className="" aria-hidden="true">
                                                    {
                                                        props.children
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}