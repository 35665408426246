import { Link } from "react-router-dom";
export default function NavLink(props) {
    const { to, isActive, label } = props
    return (
        <Link to={to} className={`w-full flex rounded-lg hover:bg-slate-100 ${isActive ? 'bg-indigo-100 border-2 border-indigo-500 font-bold text-indigo-600' : ''}`}>
            <span className="p-2 w-full">
                {label}
            </span>
        </Link>
    )
}