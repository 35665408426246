import LeadsService from "../../../services/leads.service"
import { useState } from "react"
import DotsLoading from "../../DotsLoading"
import { toast } from "react-hot-toast"
export default function FileDownloader(props) {
    const { fileKey = "", uuid = 0 } = props
    const [loading, setLoading] = useState(false)
    const handleDownLoadCV = () => {
        setLoading(!loading)
        LeadsService.downloadContractFile(uuid)
            .then(response => {
                if (response.status === 200) {
                    try {
                        const blob = new Blob([response.data], {type: "octet/stream"})
                        const link = document.createElement('a')
                        const file =  window.URL.createObjectURL(blob)
                        link.href = file
                        link.download = fileKey
                        link.click()
                        setLoading(false)
                    } catch (error) {
                        setLoading(false)
                        toast.error("Ocurrió un error al descargar el elemento, intente más tarde.")
                    }
                }
                else {
                    setLoading(false)
                    toast.error("Ocurrió un error al descargar el elemento, intente más tarde.")
                }
            })
    }
    return (
        <div className="w-full flex items-center justify-center" onClick={() => handleDownLoadCV()}>
            {
                !loading ? 
                    <>{props.children}</>
                    :
                    <div className="-bg-black -opacity-20 -rounded-md p-2">
                        <DotsLoading />
                    </div>
            }
        </div>
    )
}