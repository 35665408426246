import Modal from "../../Modal/Modal"
import { useForm, Controller } from "react-hook-form"
import { useState, useEffect } from "react"
import FormAlerts from "../../Utils/FormAlerts"
import DotsLoading from "../../DotsLoading"
import FilesService from "../../../services/files.service"
import { toast } from "react-hot-toast"
import FileUploadStatus from "./FileUploadStatus"
export default function NewFileForm(props) {
    const { type = "", modalName = "", actionLabel = "Guardar",
        formKey, showModal, setShowModal, targetDirectory = 0,
        targetMasterFile = 0, responseHandler, onlyFileInput = false,
        fetcher = FilesService.uploadFiles, allowMultiple = false } = props
    const { control, register, handleSubmit, formState: { errors }, reset, watch, getValues } = useForm()
    const [filesSelected, setFilesSelected] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([])
    const [defaultResponse, setDefaultResponse] = useState({ 'type': '', 'message': 'Ocurrió un error al procesar la solicitud.', 'show': false })
    const [loading, setLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [resetForm, setResetForm] = useState(false)
    const [uploadLoadingState, setUploadLoadingState] = useState(false)

    useEffect(() => {
        if (showModal) {
            handleResetForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal])

    useEffect(() => {
        handleResetForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetForm])

    const handleResetForm = () => {
        reset({
            "directory": "",
            "masterFile": "",
            "name": "",
            "description": "",
            "tags": "",
            "formFile": "",
            "accessType": 1
        })
        setFilesSelected(false)
        setResetForm(false)
    }

    const handleFileOnChange = () => {
        setFilesSelected(false)
        setSelectedFiles([])
        let fileValue = getValues("formFile")
        if (fileValue.length > 0) {
            setFilesSelected(true)
            let files = []
            for (const file of fileValue) {
                files.push({
                    name: file.name,
                    size: file.size,
                    finalized: false,
                    success: false,
                    oading: false,
                })
            }
            setSelectedFiles(files)
        }
    }

    const submit = async (values) => {
        //Inicializamos carga de archivos
        let arrayResponse = []
        await initFileUploadLoadingStatus()
        setShowAlert(false)
        setLoading(true)
        //Generamos un ciclo por cada archivo seleccionado
        const filesLength = values.formFile.length
        for (let index = 0; index < filesLength; index++) {
            const file = values.formFile[index]
            let uploadSuccess = false
            const uploadResponse = await upload(file)
            //Guardamos el response en un array 
            if (uploadResponse) {
                arrayResponse.push(uploadResponse)
                uploadSuccess = true
            }
            setFileUploadLoadingStatus(index, uploadSuccess)
            setUploadLoadingState(true)
        }
        await responseHandler(arrayResponse)
        handleCloseModal()
        toast.success("Se proceso la información correctamente.")
    }

    const upload = async (file) => {
        const formData = new FormData()
        formData.append("file", file)
        formData.append("directory", targetDirectory)
        formData.append("masterFile", targetMasterFile)
        return await fetcher(targetDirectory, formData)
            .then(async response => {
                if (response?.status === 200) {
                    const { masterFile } = response.data.data
                    return masterFile
                }
                return false
            })
    }

    const initFileUploadLoadingStatus = async () => {
        try {
            //Bloqueamos el selector de archivos
            let files = selectedFiles
            files.map((item) => {
                item.loading = true
            })
            setSelectedFiles(files)
        } catch (error) {
            return true
        }
    }

    const setFileUploadLoadingStatus = async (index, success) => {
        try {
            let files = selectedFiles
            let file = files[index]
            file.success = success
            file.loading = false
            file.finalized = true
            setSelectedFiles(files)
        } catch (error) {
            console.log(error)
            return true
        }
    }

    const onUploadProgress = (progressEvent) => {
        console.log("progressEvent: ", progressEvent)
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
            console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);
        }
    }

    const handleCloseModal = () => {
        setLoading(false)
        setShowModal(false)
        setFilesSelected(false)
        setSelectedFiles([])
    }

    return (
        <Modal showModal={showModal} setShowModal={handleCloseModal} title={modalName}>
            <div className="w-full">
                <div>
                    <FormAlerts showAlert={showAlert} setShowAlert={setShowAlert} defaultResponse={defaultResponse} />
                    <form id={formKey} onSubmit={handleSubmit(submit)}>
                        <div className="relative pb-4">
                            <div className="relative">
                                <input
                                    hidden
                                    id={`${formKey}_file`}
                                    type="file"
                                    {...register("formFile", { required: "Campo requerido", onChange: handleFileOnChange })}
                                    multiple={allowMultiple}
                                />
                                <label

                                    htmlFor={`${formKey}_file`}
                                    className={`truncate flex flex-col w-full ${loading ? 'loading-state' : ''}`}
                                >
                                    <div className={`text-center mt-2 truncate px-4 py-8 w-full border-2 border-dashed border-indigo-700 ${filesSelected ? 'border-green-500 bg-green-100' : 'bg-indigo-100'} hover:bg-indigo-200 rounded-md cursor-pointer`}>
                                        <span className="text-sm truncate font-medium text-center w-full">
                                            {
                                                selectedFiles.length > 0 ?
                                                    <>
                                                        <p>{selectedFiles.length} archivo(s) seleccionados</p>
                                                    </>
                                                    :
                                                    'Seleccionar archivo(s)'
                                            }
                                        </span>
                                    </div>
                                </label>
                            </div>
                            {errors.formFile && <p role="alert" className="text-red-800 pt-2 text-right text-sm">{errors.formFile?.message}</p>}
                        </div>
                        <div className="flex flex-col gap-2">
                            {
                                selectedFiles.length > 0 &&
                                selectedFiles.map((file, index) => {
                                    return (
                                        <div
                                            key={`selectedFile_${index}`}
                                            className="p-4 border border-slate-200 rounded-md flex flex-row justify-between items-center gap-2"
                                        >
                                            <p className="text-xs font-bold">{file.name}</p>
                                            <FileUploadStatus pending={true} loading={file.loading} finalized={file.finalized} success={file.success} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="relative pt-8">
                            <div className="w-auto">
                                <button type="submit" className={`font-medium w-full h-[50px] flex items-center justify-center bg-indigo-600 drop-shadow-md hover:bg-indigo-800 text-white focus:ring-4 rounded-md px-8 py-2.5 text-center btn-send-comments ${loading ? 'loading-state' : ''}`}>
                                    {
                                        loading ?
                                            <DotsLoading />
                                            :
                                            <span>{actionLabel}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        </Modal >
    )
}