import axios from "./axios_interceptor"
const API_URL = process.env.REACT_APP_API_URL

const downloadLeadsReport = ({ params = false } = {}) => {
    console.log(params)
    return axios.get(`${API_URL}/crm/reports/leads`, { ...(params && { params }), responseType: 'blob'})
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
            return error.response
        })
}

export default {
    downloadLeadsReport
}