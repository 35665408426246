export default function StatusBadge(props) {
    const { label, status } = props
    const statusStyle = {
        'default': 'text-slate-800 bg-slate-100',
        'new': 'text-green-800 bg-green-100 font-bold',
        'pending': 'text-red-800 bg-red-100',
        'confirmed': 'text-blue-800 bg-blue-100',
        'finished': 'text-indigo-800 bg-indigo-100',
        'noshow': 'text-gray-800 bg-gray-100'
    }
    return (
        <span className={`w-fit whitespace-nowrap inline-flex items-center py-1 px-2 mr-2 text-xs rounded-lg ${statusStyle[status] ? statusStyle[status] : statusStyle['default']}`}>
            {label}
        </span>
    )
}