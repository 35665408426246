import { useMemo, useState, useEffect } from "react";
import { useTable, usePagination, useSortBy } from "react-table"
import TableLoader from "../TableLoader";
import { MdNavigateBefore, MdNavigateNext, MdFirstPage, MdLastPage, MdArrowDropDown, MdArrowDropUp, MdOutlineSearchOff, MdFilterAlt } from "react-icons/md"
export default function Table(props) {
  const { tableColumns = [], tableItems = [], loading = false, filterKeys = [], setTableItems, hiddenColumns = [] } = props
  const [filterTarget, setFilterTarget] = useState(filterKeys[0]?.key)
  const [filterInput, setFilterInput] = useState('')
  const [filterData, setFilterData] = useState([])

  useEffect(() => {
    setFilterData(tableItems)
  }, [tableItems]);

  const data = useMemo(
    () => filterData
  )
  const columns = useMemo(
    () => tableColumns
  )
  const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0, hiddenColumns } }, useSortBy, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = tableInstance

  const handleFilter = () => {
    //filtramos y seteamos
    let prev = [...tableItems]
    let filter = prev
    let input = filterInput.toLowerCase().trim()
    if ( input !== "") {
      filter = [...filterData]
      filter = prev.filter((item) => {
        return item[filterTarget]?.toLowerCase().includes(input)
      })
    }
    setFilterData(filter)
  }

  const handleResetFilter = () => {
    setFilterInput("")
    setFilterData([...tableItems])
  }

  return (
    <>
      {
        loading ?
          <TableLoader />
          :
          <>
            <div className="pb-8 flex justify-end gap-4">
              <select
                onChange={(e)=> setFilterTarget(e.target.value)}
                className="rounded-lg focus:outline-none focus:border-indigo-700 focus:ring-1 focus:ring-indigo-500"
                name=""
                id="">
                {
                  filterKeys.map((item, index) => {
                    return (
                      <option key={`opt_filter_${index}`} value={item.key}>{item.label}</option>
                    )
                  })
                }
              </select>
              <input
                value={filterInput}
                onChange={(e) => (setFilterInput(e.target.value))}
                className="rounded-lg focus:outline-none focus:border-indigo-700 focus:ring-1 focus:ring-indigo-700"
                type="text" />
              <button
                onClick={() => handleFilter()}
                className="flex items-center gap-2 text-white bg-indigo-700 px-4 py-2 rounded-lg font-bold hover:bg-indigo-500"
                type="button">
                Filtrar <MdFilterAlt />
              </button>
              <button
                onClick={() => handleResetFilter()}
                className="underline hover:bg-indigo-500 hover:text-white rounded-lg px-4 py-2">
                <MdOutlineSearchOff className="w-6 h-6"
                />
              </button>
            </div>
            <div className="overflow-x-auto pb-4 mb-4">
              <table
                {...getTableProps()}
                className="w-full text-sm text-left whitespace-nowrap">
                <thead
                  className="text-gray-700 uppercase bg-gray-50">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className="py-2 px-2"
                        >
                          <div className="flex flex-row items-center text-xs">
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? <MdArrowDropDown className="w-6 h-6" />
                                  : <MdArrowDropUp className="w-6 h-6" />
                                : ''}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map(row => {
                    prepareRow(row)
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="bg-white border-b"
                      >
                        {row.cells.map(cell => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="py-4 px-2"
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className="pagination flex flex-row items-center gap-4 justify-between">
              <select
                className="rounded-lg border border-indigo-800"
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize}
                  </option>
                ))}
              </select>
              <div>
                <span>
                  Página{' '}
                  <strong>
                    {pageIndex + 1} de {pageOptions.length}
                  </strong>{' '}
                </span>
                {' | '}
                <span>
                  Ir a página:{' '}
                  <input
                    className="rounded-lg border border-indigo-800"
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0
                      gotoPage(page)
                    }}
                    style={{ width: '80px' }}
                  />
                </span>{' '}

              </div>
              <div className="flex flex-row gap-2 items-center">
                <button
                  className="hover:bg-indigo-100 border border-indigo-800 cursor-pointer rounded-lg text-indigo-800 p-1"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <MdFirstPage className="h-6 w-6" />
                </button>
                <button
                  className="hover:bg-indigo-100 border border-indigo-800 cursor-pointer rounded-lg text-indigo-800 p-1"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <MdNavigateBefore className="h-6 w-6" />
                </button>
                <button
                  className="hover:bg-indigo-100 border border-indigo-800 cursor-pointer rounded-lg text-indigo-800 p-1"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <MdNavigateNext className="h-6 w-6" />
                </button>
                <button
                  className="hover:bg-indigo-100 border border-indigo-800 cursor-pointer rounded-lg text-indigo-800 p-1"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <MdLastPage className="h-6 w-6" />
                </button>
              </div>
            </div>
          </>
      }
    </>
  )
}