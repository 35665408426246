import FileVersionItem from "./FileVersionItem"
import { MdSync, MdBlurOn, MdClose, MdUpgrade, MdPerson, MdCalendarToday, MdCopyAll, MdSecurity } from "react-icons/md"
import { format } from "date-fns"
import FileIcon from "./FileIcon"
import FileDownloader from "./FileDownloader"
export default function FilePreview(props) {
    const { masterFile = {}, handleClose, responseHandler } = props
    const { files = [] } = masterFile
    return (
        <>
            <div
                className="flex w-full min-h-[50vh] max-h-[100vh]"
            >
                <div className="relative w-full bg-slate-100 pt-4 pb-8 px-4 rounded-md drop-shadow-md overflow-x-hidden overflow-y-auto">
                    <div className="mb-4">
                        <p className="text-center font-bold text-lg">File Preview</p>
                    </div>
                    {
                        files.length > 0 ?
                            <div className="">
                                <div className="flex flex-col gap-1 mb-4 break-words">
                                    <div className="flex justify-center mb-4">
                                        <FileIcon size="lg" fileType={files[0].type} />
                                    </div>
                                    <p className="font-bold text-center">{masterFile.name}</p>
                                    <FileDownloader fileKey={files[0].key} uuid={files[0].uuid}>
                                        <p
                                            className="font-bold text-blue-500 text-center hover:underline hover:cursor-pointer">
                                            {files[0].key}
                                        </p>
                                    </FileDownloader>
                                    <p className="font-bold text-sm text-center pt-2">{masterFile.description}</p>
                                    <div className="pt-4 flex flex-col justify-center items-center gap-2">
                                        <p className="!m-0 font-bold text-[10px] flex gap-1 items-center">
                                            <MdSync className="w-3 h-3" /><span>{format(new Date(files[0].createdAt), 'dd-MM-yyyy')}</span>
                                        </p>
                                        <p className="!m-0 font-bold text-[10px] flex gap-1 items-center">
                                            <MdPerson className="w-3 h-3" />{masterFile.createdBy}
                                        </p>
                                        <p className="!m-0 font-bold text-[10px] flex gap-1 items-center">
                                            <MdCalendarToday className="w-3 h-3" /><span>{format(new Date(masterFile.createdAt), 'dd-MM-yyyy')}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="border-t"></div>
                                <div className="pt-4">
                                    <p className="font-bold text-sm mb-4">Versiones del archivo</p>
                                    <div>
                                        {
                                            files.map((file, indexFilePrev) => {
                                                return <FileVersionItem key={`filePrev_${indexFilePrev}`} file={file} />
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="w-full flex flex-col gap-4 justify-center items-center">
                                <MdBlurOn className="w-[8rem] h-[8rem] text-slate-200" />
                                <div>
                                    <p className="text-slate-400 text-sm">No se encontraron archivos en el directorio</p>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </>
    )
}