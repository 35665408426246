import {
    Routes,
    Route,
    Navigate
} from "react-router-dom"
import Dashboard from "./Dashboard"
import ReservasServicios from "./ReservasServicios/Reservas"
import Leads from "./Leads/Leads"
import LeadDetail from "./Leads/LeadDetail"
import ProtectedRoute from "../ProtectedRoute"
import RequirePermission from "../../auth/RequierePermission"
export default function Index() {
    return (
        <>
            <Routes>
                <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route element={<RequirePermission app={'reservas_servicios'} allowedRoles={['servicios_rsv_access']} />}>
                        <Route element={<RequirePermission app={'reservas_servicios'} allowedRoles={['hunab_ku_service']} />}>
                            <Route path="/services-reservation/hunab_ku_rest" element={<ReservasServicios code={"hunab_ku_rest"} />} />
                        </Route>
                        <Route element={<RequirePermission app={'reservas_servicios'} allowedRoles={['ixchel_spa_service']} />}>
                            <Route path="/services-reservation/ixchel-spa" element={<ReservasServicios code={"ixchel-spa"} />} />
                        </Route>
                    </Route>
                    <Route element={<RequirePermission app={'crm'} allowedRoles={['crm-access']} />} >
                        <Route path="/leads" element={<Leads />} />
                        <Route path="/leads/:id" element={<LeadDetail />} />
                    </Route>
                </Route>
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </>
    )
}