import SlidingMenu from "../../../SlidingMenu"
import { useForm, Controller } from "react-hook-form"
import { useState, useEffect } from "react"
import DotsLoading from "../../../DotsLoading"
import LeadsService from "../../../../services/leads.service"
import { toast } from "react-hot-toast"
import Select from "react-select"
export default function AddNote(props) {
    const { clientRequestId, manageNotes, showSlide, setShowSlide, channels = [] } = props
    const { control, register, handleSubmit, formState: { errors }, reset } = useForm()
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    useEffect(() => {
        reset({
            "trackingChannel": "",
            "note": ""
        })
    }, [showSlide])
    let channelOptions = []
    if (channels.length > 0) {
        channels.map((item) => {
            channelOptions.push({
                value: item.code,
                label: item.description
            })
        })
    }
    const submit = (props) => {
        let trackingChannel = props?.trackingChannel?.value
        const params = {
            clientRequestId: clientRequestId,
            note: props.note,
            trackingChannel: trackingChannel
        }
        setLoadingSubmit(true)
        LeadsService.createNote(params)
            .then(response => {
                if (response.status === 201) {
                    toast.success("Se actualizó la información correctamente")
                    manageNotes(response.data.data.note)
                    setShowSlide(false)
                    reset({ "note": "" })
                }
                else {
                    toast.error("Ocurrió un error al actualizar la información")
                }
                setLoadingSubmit(false)
            })

    }
    return (
        <SlidingMenu {...props} panelTitle={"Seguimiento"}>
            <form id="addNoteForm" onSubmit={handleSubmit(submit)}>
                <div className="relative pb-4">
                    <label htmlFor="name" className="block mb-2 font-medium text-gray-900">Canal</label>
                    <Controller
                        name="trackingChannel"
                        control={control}
                        rules={{
                            required: "Campo requerido",
                        }}
                        render={({ field }) =>
                            <Select
                                className="select"
                                isLoading={channelOptions.length === 0}
                                options={channelOptions}
                                isSearchable={false}
                                {...field}
                            />
                        }
                    />
                    {errors.trackingChannel && <p role="alert" className="text-red-800 pt-2 text-right text-sm">{errors.trackingChannel?.message}</p>}
                </div>
                <div className="relative pb-4">
                    <label htmlFor="name" className="block mb-2 font-medium text-gray-900">Nota: </label>
                    <textarea
                        className={`bg-gray-50 border border-gray-300 text-gray-900  rounded-md block w-full p-2.5`}
                        autoComplete="off"
                        defaultValue={""}
                        {...register("note", { required: "Campo requerido" })}
                        cols="30"
                        rows="5">
                    </textarea>
                    {errors.note && <p role="alert" className="text-red-800 pt-2 text-right text-sm">{errors.note?.message}</p>}
                </div>
                <div className="relative py-4 flex">
                    <button
                        type="submit"
                        className={`w-full p-3 rounded-lg bg-indigo-700 hover:bg-indigo-500 text-white ${loadingSubmit ? 'loading-state' : ''}`}>
                        {
                            loadingSubmit ?
                                <DotsLoading />
                                :
                                <strong>Agregar</strong>
                        }
                    </button>
                </div>
            </form>
        </SlidingMenu>
    )
}