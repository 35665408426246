import SlidingMenu from "../../SlidingMenu"
import { useForm, Controller } from "react-hook-form"
import Select from "react-select"
import StatusHistory from "./StatusHistory"
import { useState } from "react"
import DotsLoading from "../../DotsLoading"
import LeadsService from "../../../services/leads.service"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
export default function LeadStatus(props) {
    const { statusList = [], setShowSlide, clientRequestId = 0, statusHistory = [], handleChangeStatusResponse } = props
    const { control, register, handleSubmit, formState: { errors }, reset } = useForm()
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const navigate = useNavigate()
    let statusOptions = []
    if (statusList.length > 0) {
        statusList.map((item) => {
            statusOptions.push({
                value: item.id,
                label: item.description
            })
        })
    }
    const submit = (props) => {
        let statusOptionId = props?.leadStatus?.value
        let params = {
            clientRequestId,
            statusOptionId
        }
        setLoadingSubmit(true)
        LeadsService.updateRequestStatus(params)
            .then(response => {
                if (response.status === 200) {
                    toast.success("Se actualizó la información correctamente")
                    handleSubmitResponse(statusOptionId)
                    setShowSlide(false)
                }
                else {
                    toast.error("Ocurrió un error al actualizar la información")
                }
                setLoadingSubmit(false)
            })
    }

    const handleSubmitResponse = (target) => {
        const statusSelected = statusList.filter(item => item.id === target)
        handleChangeStatusResponse(statusSelected)
    }
    return (
        <>
            <SlidingMenu {...props} panelTitle={"Estatus"}>
                <form id="followUpForm" onSubmit={handleSubmit(submit)}>
                    <div className="relative pb-4">
                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Estatus</label>
                        <Controller
                            name="leadStatus"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) =>
                                <Select
                                    className="select"
                                    isLoading={statusOptions.length === 0}
                                    options={statusOptions}
                                    isSearchable={false}
                                    {...field}
                                />
                            }
                        />
                    </div>
                    <div className="relative py-4 flex justify-end">
                        <button
                            type="submit"
                            className={`w-full p-3 rounded-lg bg-indigo-700 hover:bg-indigo-500 text-white ${loadingSubmit ? 'loading-state' : ''}`}>
                            {
                                loadingSubmit ?
                                    <DotsLoading />
                                    :
                                    <strong>Actualizar</strong>
                            }
                        </button>
                    </div>
                </form>
                <div className="py-8">
                    <StatusHistory reqStatusHistory={statusHistory} />
                </div>

            </SlidingMenu>
        </>
    )
}