import { format } from "date-fns"
import Badge from "../../Badge"
import { MdOutlineSensors, MdPerson, MdOutlineCalendarToday} from "react-icons/md"
export default function TrackingHistory(props) {
    const { tracking = [] } = props
    return (
        <ol className="relative border-l border-gray-200">
            {
                tracking.map((note, index) => {
                    return (
                        <li key={`trackingHistory_item_${index}`} className="mb-10 ml-6">
                            <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white">
                                <MdOutlineSensors className="w-4 h-4 text-blue-800" />
                            </span>
                            <div key={`note_${index}`} className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                                <div className="items-center justify-between mb-4 sm:flex">
                                    <time className="flex items-center gap-2 mb-1 text-xs font-bold text-blue-600 sm:order-last sm:mb-0"><MdOutlineCalendarToday /> {format(new Date(note?.createdAt), 'dd-MM-yyyy')}</time>
                                    <div className="text-xs font-normal text-gray-500 lex flex flex-col gap-2">                                        
                                        <span className="bg-indigo-100 text-indigo-800 text-xs font-bold mr-2 px-2.5 py-0.5 rounded w-fit">
                                            {note?.channel?.description}                                                                                        
                                        </span>
                                        <span className="text-blue-600 font-bold text-xs flex items-center gap-2">
                                            <MdPerson />
                                            {note?.createdBy}
                                        </span>
                                    </div>
                                </div>
                                <div className="p-3 italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50">
                                    {note.note}
                                </div>
                            </div>
                        </li>
                    )
                })
            }

        </ol>
    )
}