import Dashboard from "./components/Dashboard"
import Login from "./components/Login"
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom"
import { Toaster } from "react-hot-toast"
function App() {
  return (
    <>
      <div><Toaster/></div>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
