import { useState } from "react"
import Modal from "../Modal/Modal"
import FilterConfiguration from "./FilterSelector/FilterConfiguration"
import DotsLoading from "../DotsLoading"
//Creamos un contexto para la seccion de filtros
export default function Filters(props) {
    const { setFilterInput, handleDefFilter, filterActionConfig, exportData, loadingReport } = props
    const [showModal, setShowModal] = useState(false)

    const handleShowModal = () => {
        setShowModal(true)
        filterActionConfig.setFilterAction({ ...filterActionConfig.filterAction, loading: false })
    }
    return (
        <>
            <div className="w-full">
                <div className="flex flex-row gap-8 justify-between w-full">
                    <div className="search-bar relative w-full h-fit opacity-50 pointer-events-none cursor-not-allowed">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input
                            type="search"
                            id="default-search"
                            onChange={(e) => setFilterInput(e.target.value)}
                            className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Busqueda rápida por nombre o correo..." required />
                        <button 
                            onClick={(e) => handleDefFilter()}
                            type="button" 
                            className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Buscar
                        </button>
                    </div>
                    <div className="filter-buttons flex items-center flex-row gap-4">
                        <button
                            className="h-fit flex items-center gap-2 py-2 px-4 bg-indigo-700 hover:bg-indigo-500 text-white rounded-lg font-bold"
                            onClick={() => handleShowModal()}
                        >
                            Filtrar
                        </button>
                        <div className="border h-full"></div>
                        <button
                            onClick={(e) => exportData()}
                            className={` ${loadingReport ? 'loading-state' : ''} h-fit min-w-[120px] flex items-center justify-center gap-2 py-2 px-4 border border-slate-300 bg-slate-200 hover:bg-slate-300 rounded-lg font-bold`}>
                            {
                                !loadingReport ?
                                <span>Exportar</span>
                                :
                                <DotsLoading />                        
                            }
                        </button>
                    </div>
                </div>
            </div>
            <FilterConfiguration
                {...props}
                showModal={showModal}
                setShowModal={setShowModal}
            />
        </>
    )
}