import Container from "../Container"
import { useEffect, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import ServicesReservationService from "../../../services/services_reservation.service"
import Table from "../Table"
import Detail from "./Detail"
import { useParams } from "react-router-dom"
import { format } from "date-fns"
import Badge from "../Badge"
export default function Reservas(props) {
    const { code } = props
    const navigate = useNavigate()
    const [reservations, setReservations] = useState([])
    const [loading, setLoading] = useState(true)
    const [showSlide, setShowSlide] = useState(false)
    const [reservationSelected, setReservationSelected] = useState({})
    const tableColumns = [
        {
            Header: 'Fecha Creación',
            accessor: 'dateFormat',
            sortType: useMemo(
                () => (rowA, rowB, columnId) => {
                    const a = new Date(rowA.values[columnId].props['label']);
                    const b = new Date(rowB.values[columnId].props['label']);
                    return a > b ? 1 : -1;
                },
                []
            )
        },
        {
            Header: 'Servicio',
            accessor: 'service', // accessor is the "key" in the data
            disableSortBy: true
        },
        {
            Header: 'Check-In',
            accessor: 'checkinFormat', // accessor is the "key" in the data
            sortType: useMemo(
                () => (rowA, rowB, columnId) => {
                    const a = new Date(rowA.values[columnId].props['label']);
                    const b = new Date(rowB.values[columnId].props['label']);
                    return a > b ? 1 : -1;
                },
                []
            )
        },
        {
            Header: 'Nombre',
            accessor: 'nameFormat',
        },
        {
            Header: 'email',
            accessor: 'email',
        },
        /*
        {
            Header: 'Teléfono',
            accessor: 'phone',
        },
        */
        {
            Header: 'Status',
            accessor: 'followUp',
            disableSortBy: true
        },
        {
            Header: '',
            accessor: 'details',
            disableSortBy: true
        }
    ]
    useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            ServicesReservationService.getReservations({ service: code })
                .then(response => {
                    if (response.status === 200) {
                        formatData(response.data.data.reservations)
                    }
                })
        }
        return () => (isSubscribed = false)
    }, [code])

    const formatData = (data) => {
        data.map(function (item) {
            if (item?.request) {
                const { service, channel, followUps, date } = item.request
                item.dateFormat = <Badge label={format(new Date(item.createdAt), 'dd-MM-yyyy')} />
                item.checkinFormat = <Badge label={format(new Date(date), 'dd-MM-yyyy')} />
                item.nameFormat = `${item.name} ${item.lastName}`
                item.service = service?.description
                item.followUp = <StatusBadge status={followUps[0].code} label={followUps[0].description} />
                item.details = <button className="text-blue-600 font-bold hover:underline" onClick={() => handleReservationDetail(item)}>Detalles</button>
            }
            return item
        })
        setReservations(data)
        setLoading(false)
    }
    function StatusBadge({ status, label }) {
        const statusStyle = {
            'pending': 'text-yellow-800 bg-yellow-100',
            'confirmed': 'text-green-800 bg-green-100',
            'finished': 'text-blue-800 bg-blue-100',
            'noshow': 'text-gray-800 bg-gray-100'
        }
        return (
            <span className={`whitespace-nowrap inline-flex items-center py-1 px-2 mr-2 text-sm font-medium rounded-lg ${statusStyle[status]}`}>
                {label}
            </span>
        )
    }

    const handleReservationDetail = (item) => {
        setReservationSelected(item)
        setShowSlide(true)
    }
    return (
        <>
            <Container>
                <div className="flex items-center w-full justify-between">
                    <h1 className="font-bold text-2xl">Reservas</h1>
                    <button
                        className="py-2 px-4 bg-blue-600 text-white rounded-lg font-bold hidden"
                        onClick={() => navigate("/services-reservation/create")}
                    >
                        Nueva Reservación
                    </button>
                </div>
                <div className="pt-8">
                    <Table 
                        tableColumns={tableColumns} 
                        tableItems={reservations} 
                        loading={loading}
                        filterKeys={[{key:'nameFormat', label: 'Nombre'},{key:'email',label:'Email'}]}
                    />
                </div>
                <Detail showSlide={showSlide} setShowSlide={setShowSlide} data={reservationSelected} />
            </Container>
        </>
    )
}