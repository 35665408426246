import { useState, useEffect } from "react"
import DirectoryMasterFiles from "../../../Utils/FileManager/DirectoryMasterFiles"
export default function EmployeeFiles(props) {
    const { client, active } = props
    const [loadingComponent, setLoadingComponent] = useState(true)
    useEffect(() => {
        if (client && active) {
            setLoadingComponent(false)
        }
    }, [client, active])
    return (
        <>
            {
                !loadingComponent &&
                    <DirectoryMasterFiles directory={client} />
            }
        </>
    )
}