import axios from "./axios_interceptor"
const API_URL = process.env.REACT_APP_API_URL

const getReservations = (params) => {
  return axios.get(`${API_URL}/services-reservation/reservations`, {params: params})
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const createJob = (data) => {
  return axios.post(`${API_URL}/jobs`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getJob = (id) => {
  return axios.get(`${API_URL}/jobs/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const updateJob = (data) => {
  return axios.put(`${API_URL}/jobs/${data.id}`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

export default {
  getReservations,
  createJob,
  getJob,
  updateJob
}
