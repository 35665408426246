import { Link, useLocation } from "react-router-dom"
import verifyAccess from "../../../auth/verifyAccess"
import { FaChevronCircleDown } from "react-icons/fa"
import { useEffect, useState } from "react"
export default function MenuItems(props) {
    let location = useLocation()
    const { label = "", target = "" } = props
    const [dropdown, setDropdown] = useState(false)
    const isActive = (target) => {
        let arrayUrl = location.pathname.replace("/", "").split("/")
        let path = arrayUrl[0]
        return target === path
    }
    useEffect(() => {
        if(isActive(target)){
            setDropdown(true)
        }
    }, [isActive(target)])
    return (
        <li>
            <button
                aria-expanded={dropdown ? "true" : "false"}
                onClick={() => setDropdown((prev) => !prev)}
                className={`w-full p-2 text-start flex items-center justify-evenly rounded-lg hover:bg-slate-100 ${isActive(target) ? 'bg-indigo-100 border-2 border-indigo-500 font-bold text-indigo-600' : ''}`}
            >
                <span className="w-full">
                    {label}
                </span>
                <FaChevronCircleDown className={`${dropdown ? "rotate-180" : ""}`} />
            </button>
            <div className={`${dropdown ? "show" : "hidden"}`}>
                {
                    props.children
                }
            </div>
        </li>
    )
}