export default function AssignedUsers(props){
    const { assigned = [], users = []} = props
    if (assigned.length === 0) return <></>
    const filter = users.filter((item) => item.id === assigned[0].userId)
    return (
        <>
            {

                filter ?
                    filter.length > 0 &&
                    <div className="flex flex-row items-center gap-2 font-medium">
                        <div className="flex flex-col">
                            <span className="text-sm">{filter[0].name} {filter[0].lastName}</span>
                            <span className="text-xs">
                                {filter[0].email}
                            </span>
                        </div>

                    </div>

                    :
                    <></>
            }
        </>
    )
}