import Modal from "../../Modal"
import { useForm, Controller } from "react-hook-form"
import { useState, useEffect } from "react"
import FormAlerts from "../../FormAlerts"
import DotsLoading from "../../DotsLoading"
import { toast } from "react-hot-toast"
export default function NewFileForm(props) {
    const { modalName = "", actionLabel = "Guardar", formKey, showModal, setShowModal, target, fetcher, responseHandler } = props
    const { control, register, handleSubmit, formState: { errors }, reset, getValues } = useForm()
    const [fileName, setFileName] = useState("")
    const [fileSelected, setFileSelected] = useState(false)
    const [defaultResponse, setDefaultResponse] = useState({ 'type': '', 'message': 'Ocurrió un error al procesar la solicitud.', 'show': false })
    const [loading, setLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [resetForm, setResetForm] = useState(false)
    useEffect(() => {
        if(resetForm){
            handleResetForm()
        }      
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetForm])

    useEffect(() => {
        if(showModal){
            handleResetForm()
        }  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal])

    const handleResetForm = () => {
        reset({
            "name": "",
            "description": "",
            "formFile": ""
        })
        setFileSelected(false)
        setFileName("")
        setResetForm(false)
        setShowAlert(false)
    }

    const handleFileOnChange = () => {
        setFileSelected(false)
        setFileName("")
        let fileValue = getValues("formFile")
        if (fileValue.length > 0) {
            let fileName = fileValue[0].name
            setFileName(fileName)
            setFileSelected(true)
        }
    }

    const submit = async (values) => {
        setShowAlert(false)
        setLoading(true)
        const formData = new FormData()
        formData.append("file", values.formFile[0])
        formData.append("name", values.name)
        formData.append("description", values.description)
        fetcher(target, formData)
            .then(response => {
                if (response.status === 200) {
                    responseHandler(response.data.data)
                    setResetForm(true)
                    setShowModal(false)
                    toast.success("Se proceso la información correctamente.")
                }
                else {
                    setShowAlert(true)
                    toast.error("Ocurrió un error al procesar la información.")
                }
                setLoading(false)
            })
    }
    return (
        <Modal showModal={showModal} setShowModal={setShowModal}>
            <div className="w-full">
                <div>
                    <div className="mb-4">
                        <div className="flex justify-center">
                            <p>
                                <strong>{modalName}</strong>
                            </p>
                        </div>
                    </div>
                    <FormAlerts showAlert={showAlert} setShowAlert={setShowAlert} defaultResponse={defaultResponse} />
                    <form id={formKey} onSubmit={handleSubmit(submit)}>
                        <div className="relative flex gap-4 pb-4">
                            <div className="w-full">
                                <label htmlFor={`${formKey}_name`} className="font-medium text-sm">Nombre</label>
                                <input
                                    id={`${formKey}_name`}
                                    type="text"
                                    className={`mt-2 bg-slate-50 border border-slate-300 text-gray-900  rounded-md block w-full p-2.5 ${loading ? 'loading-state' : ''}`}
                                    autoComplete="off"
                                    defaultValue={""}
                                    {...register("name", { required: "Campo requerido" })}
                                />
                                {errors.name && <p role="alert" className="text-red-800 pt-2 text-right text-sm">{errors.name?.message}</p>}
                            </div>
                        </div>
                        <div className="relative flex gap-4 pb-4">
                            <div className="w-full">
                                <label htmlFor={`${formKey}_description`} className="font-medium text-sm">Descripción</label>
                                <textarea
                                    id={`${formKey}_description`}
                                    type="text"
                                    className={`mt-2 bg-slate-50 border border-slate-300 text-gray-900  rounded-md block w-full p-2.5 ${loading ? 'loading-state' : ''}`}
                                    autoComplete="off"
                                    defaultValue={""}
                                    {...register("description")}
                                />
                                {errors.name && <p role="alert" className="text-red-800 pt-2 text-right text-sm">{errors.name?.message}</p>}
                            </div>
                        </div>
                        <div className="relative pb-4">
                            <div className="relative">
                                <input
                                    hidden
                                    id={`${formKey}_file`}
                                    type="file"
                                    {...register("formFile", { required: "Campo requerido", onChange: handleFileOnChange })}
                                />
                                <label
                                    htmlFor={`${formKey}_file`}
                                    className={`truncate flex flex-col w-full`}
                                >
                                    <span className="font-medium text-sm">Archivo</span>
                                    <div className={`text-center mt-2 truncate p-4 w-full border border-dashed ${fileSelected ? 'border-green-500 bg-green-100' : 'bg-indigo-100'} hover:border-indigo-300 hover:bg-indigo-200 rounded-md cursor-pointer`}>
                                        <span className="truncate font-medium text-center w-full">
                                            {fileSelected ? fileName : 'Seleccionar archivo'}
                                        </span>
                                    </div>
                                </label>
                            </div>
                            {errors.cvFile && <p role="alert" className="text-red-800 pt-2 text-right text-sm">{errors.cvFile?.message}</p>}
                        </div>
                        <div className="relative pt-8">
                            <div className="w-auto">
                                <button type="submit" className={`font-medium w-full h-[50px] flex items-center justify-center bg-indigo-600 drop-shadow-md hover:bg-indigo-800 text-white focus:ring-4 rounded-md px-8 py-2.5 text-center btn-send-comments ${loading ? 'loading-state' : ''}`}>
                                    {
                                        loading ?
                                            <DotsLoading />
                                            :
                                            <span>{actionLabel}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}