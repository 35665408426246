import { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdFilterAltOff } from "react-icons/md"
export default function DateSelect(props) {
    const {startDate = 0, endDate = 0, handleSelectDate} = props
    const onChange = (dates) => {
        const [start, end] = dates; 
        handleSelectDate(start, end)     
    };

    const handleReset = () => {
        handleSelectDate(0, 0)
    }

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <button 
            className="border p-2 text-sm rounded-lg bg-slate-100 hover:bg-slate-200 flex w-full h-fit min-w-[120px]" 
            onClick={onClick} 
            ref={ref}
        >
            {value}
        </button>
    ));
    
    return (
        <div className="w-full flex">
            <ReactDatePicker
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                ecustomInput={<CustomInput />}
                calendarClassName="z-[98]"
                className="w-full border rounded-lg text-xs"
            />
            <button 
                onClick={(e)=>handleReset()}
                className="ml-4 p-2 hover:bg-slate-100 rounded-md"
            >
                <MdFilterAltOff />
            </button>
        </div>
    );
}