import { MdBusiness } from "react-icons/md"
export default function PropertyTag(props) {
    const { label = '', type = '' } = props
    if (label === '') return (<></>)
    const Content = (props) => {
        const { type } = props
        const statusStyle = {
            'corp': 'bg-miagold',
            'miareefim': 'bg-miapink',
            'miabacalar': 'bg-miabac',
            'miacityvsa': 'bg-miacyan',
            'lunaazulbac': 'bg-lunaazulbac',
            'miavacationclub': 'bg-miavacationclub',
            'default': 'bg-slate-400'
        }
        return (
            <span className={`w-fit whitespace-nowrap inline-flex gap-1 items-center py-1 px-2 mr-2 text-xs font-medium rounded-lg text-white ${statusStyle[type] ? statusStyle[type] : statusStyle['default']}`}>
                <MdBusiness /> <span>{label}</span>
            </span>
        )

    }

    return (
        <Content label={label} type={type} />
    )
}