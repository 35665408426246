import SlidingMenu from "../../SlidingMenu"
import { useForm, Controller } from "react-hook-form"
export default function Detail(props) {
    //const { showSlide, setShowSlide, panelTitle } = props
    const { data, showSlide } = props
    const { request = {} } = data
    const { service = {}, occasion = {} } = request
    return (
        <>
            <SlidingMenu {...props} panelTitle={"Detalle Reserva"}>
                <form action="">
                    <div className="relative pb-4">
                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Nombre</label>
                        <input defaultValue={data.name} type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5" />
                    </div>
                    <div className="relative pb-4">
                        <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900">Apellido</label>
                        <input defaultValue={data.lastName} type="text" id="lastName" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5" />
                    </div>
                    <div className="relative pb-4">
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Correo</label>
                        <input defaultValue={data.email} type="text" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5" />
                    </div>
                    <div className="relative pb-4">
                        <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">Teléfono</label>
                        <input defaultValue={data.phone} type="text" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5" />
                    </div>
                    <div className="relative pb-4">
                        <label htmlFor="service" className="block mb-2 text-sm font-medium text-gray-900">Servicio</label>
                        <input defaultValue={service.description} type="text" id="service" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5" />
                    </div>
                    <div className="relative pb-4">
                        <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900">Fecha</label>
                        <input defaultValue={request.date} type="text" id="date" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5" />
                    </div>
                    <div className="relative pb-4">
                        <label htmlFor="hour" className="block mb-2 text-sm font-medium text-gray-900">Hora</label>
                        <input defaultValue={request.hour} type="text" id="hour" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5" />
                    </div>
                    <div className="relative pb-4">
                        <label htmlFor="pax" className="block mb-2 text-sm font-medium text-gray-900">Personas</label>
                        <input defaultValue={request.pax} type="text" id="pax" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5" />
                    </div>
                    <div className="relative pb-4">
                        <label htmlFor="ocassion" className="block mb-2 text-sm font-medium text-gray-900">Motivo</label>
                        <input defaultValue={occasion?.description}  type="text" id="ocassion" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5" />
                    </div>
                    <div className="relative pb-4">
                        <label htmlFor="ocassion" className="block mb-2 text-sm font-medium text-gray-900">Solicitud Especial</label>
                        <textarea defaultValue={request.customRequest} name="special_request" id="special_request" cols="30" rows="4" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"></textarea>
                    </div>
                </form>            
            </SlidingMenu>
        </>
    )
}