import { memo, useMemo, useReducer, useState, useEffect } from "react";
import TableLoader from "../TableLoader";
import {
  MdNavigateBefore,
  MdNavigateNext,
  MdFirstPage,
  MdLastPage,
  MdArrowDropDown,
  MdArrowDropUp,
  MdOutlineSearchOff,
  MdFilterAlt,
  MdSearch,
} from "react-icons/md";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
} from "@tanstack/react-table";
import Filters from "./Filters";
import Table from "./Table";
const ReactTable = (props) => {
  const {
    tableColumns = [],
    tableItems = [],
    loading = false,
    filterKeys = [],
    filterConfig = [],
    filterActionConfig = null,
    exportData = false,
    loadingReport = false,
    setLoadingReport = null,
  } = props;
  const rerender = useReducer(() => ({}), {})[1];
  const [filterTarget, setFilterTarget] = useState(filterKeys[0]?.key);
  const [filterInput, setFilterInput] = useState("");
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    setFilterData(tableItems);
  }, [tableItems]);

  const data = useMemo(() => tableItems, [tableItems]);
  const columns = useMemo(() => tableColumns, [tableColumns]);

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };
  const defaultData = useMemo(() => [], []);

  /*
    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )
    */

  const handleFilter = () => {
    //filtramos y seteamos
    let prev = [...tableItems];
    let filter = prev;
    const target = filterInput?.trim()?.toLocaleLowerCase() ?? false;
    if (target) {
      filter = [...filterData];
      //Genereamos un nuevo array con las keys proporcionadas
      filter = prev.filter((item) => {
        //Validamos en todas las filterKeys proporcionadas
        const search_arr = filterKeys.map((key) => {
          let itemTarget = item[key] ?? "";
          return itemTarget.toLowerCase();
        });
        const evaluate =
          search_arr.filter((x) => x.includes(target)).length > 0;
        return evaluate;
      });
    }
    setFilterData(filter);
  };

  return (
    <>
      {loading ? (
        <TableLoader />
      ) : (
        <div className="rounded-t-md border overflow-hidden">
          {filterConfig.length > 0 && (
            <div className="p-8 flex gap-4 border-b">
              <Filters
                exportData={exportData}
                filterConfig={filterConfig}
                filterActionConfig={filterActionConfig}
                loadingReport={loadingReport}
                setLoadingReport={setLoadingReport}
                handleDefFilter={handleFilter}
                setFilterInput={setFilterInput}
              />
            </div>
          )}
          <Table data={tableItems} columns={tableColumns} />
        </div>
      )}
    </>
  );
};

export default memo(ReactTable);
