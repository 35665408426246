import { Navigate, Outlet } from "react-router-dom"
import AuthService from "../services/auth.service"
import useAuth from "./useAuth"
import verifyAccess from "./verifyAccess"
export default function RequirePermission({ app, allowedRoles }) {
    const { access, isAdmin } = useAuth()
    if (AuthService.isLoggedIn()) {
        const content = (
            verifyAccess({ app, allowedRoles })
                ? <Outlet />
                : <Navigate to="/" />
        )
        return content
    }
    return <Navigate to="/login" />
}