import { MdHelp, MdCloudDownload, MdRemoveRedEye, MdCalendarToday, MdPerson } from "react-icons/md"
import { format } from "date-fns"
import FileIcon from "./FileIcon"
import FileDownloader from "./FileDownloader"
export default function FileVersionItem(props) {
    const { file } = props
    return (
        <div
            initial={{ scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-white drop-shadow-md border rounded-md p-4 flex items-center gap-4 mb-4"
        >
            <div>
                <FileIcon size="sm" fileType={file.type} />
            </div>
            <div className="flex w-full">
                <div className="flex flex-1 flex-col text-xs gap-1">
                    <p className="font-bold">{file.name}</p>
                    <FileDownloader fileKey={file.key} uuid={file.uuid}>
                        <p
                            className="text-blue-500 hover:underline hover:cursor-pointer">
                            {file.key}
                        </p>
                    </FileDownloader>
                    <div className="flex flex-1 flex-col">
                        <p className="font-bold text-[10px] flex gap-1 items-center">
                            <MdPerson className="w-3 h-3" />{file.createdBy}
                        </p>
                        <p className="font-bold text-[10px] flex gap-1 items-center">
                            <MdCalendarToday className="w-3 h-3" />{format(new Date(file.createdAt), 'dd-MM-yyyy')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}