import { format } from "date-fns"
import { MdPerson, MdOutlineCalendarToday } from "react-icons/md"
export default function StatusHistory(props) {
    const { reqStatusHistory } = props
    return (
        <ol className="relative border-l border-indigo-200">
            {
                reqStatusHistory.map((item, index) => {
                    return (
                        <li key={`history_item_${index}`} className="mb-10 ml-4">
                            <div className="absolute w-3 h-3 bg-indigo-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                            <h3 className="text-lg font-semibold text-gray-900 mb-2">{item?.statusOption?.description}</h3>
                            <div className="flex flex-col gap-2">
                                {
                                    item.createdBy &&
                                        <span className="flex items-center gap-2 text-sm font-normal leading-none text-gray-400">
                                            <MdPerson /> {item.createdBy}
                                        </span>
                                }
                                <time className="flex items-center gap-2 text-sm font-normal leading-none text-gray-400">
                                    <MdOutlineCalendarToday />
                                    {format(new Date(item?.createdAt), 'dd-MM-yyyy')}
                                </time>
                            </div>

                        </li>
                    )
                })
            }

        </ol>
    )
}