import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
} from "@tanstack/react-table";

import { memo } from "react";
const Table = (props) => {
  const { data, columns } = props;
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    //state: { pagination },
    //onPaginationChange: setPagination,
    getFilteredRowModel: getFilteredRowModel(),
    //getPaginationRowModel: getPaginationRowModel(),
  });
  return (
    <div className="overflow-x-auto --pb-4 --mb-4 rounded-b-md">
      <table className="w-full text-sm text-left ">
        <thead className="text-gray-700 uppercase bg-slate-100 text-xs rounded-md overflow-hidden">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="rounded-md">
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="py-4 px-8">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="bg-white border-b border-slate-100 hover:bg-gray-50"
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="py-4 px-8">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {/*
                            <div className="pagination flex flex-row items-center gap-4 justify-between px-8 py-4 text-sm">
                                <select
                                    className="rounded-md border border-indigo-800 text-sm"
                                    value={table.getState().pagination.pageSize}
                                    onChange={e => {
                                        table.setPageSize(Number(e.target.value))
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            Mostrar {pageSize}
                                        </option>
                                    ))}
                                </select>
                                <div>
                                    <span>
                                        Página{' '}
                                        <strong>
                                            {table.getState().pagination.pageIndex + 1} de{' '}
                                            {table.getPageCount()}
                                        </strong>
                                    </span>
                                    {' | '}
                                    <span>
                                        Ir a página:{' '}
                                        <input
                                            className="w-[60px] text-sm rounded-md border border-indigo-800"
                                            type="number"
                                            defaultValue={table.getState().pagination.pageIndex + 1}
                                            onChange={e => {
                                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                                table.setPageIndex(page)
                                            }}
                                        />
                                    </span>
                                </div>
                                <div className="flex flex-row gap-2 items-center">
                                    <button
                                        className="hover:bg-indigo-100 border border-indigo-800 cursor-pointer rounded-md text-indigo-800 p-1"
                                        onClick={() => table.setPageIndex(0)}
                                        disabled={!table.getCanPreviousPage()}
                                    >
                                        <MdFirstPage className="h-5 w-5" />
                                    </button>
                                    <button
                                        className="hover:bg-indigo-100 border border-indigo-800 cursor-pointer rounded-md text-indigo-800 p-1"
                                        onClick={() => table.previousPage()}
                                        disabled={!table.getCanPreviousPage()}
                                    >
                                        <MdNavigateBefore className="h-5 w-5" />
                                    </button>
                                    <button
                                        className="hover:bg-indigo-100 border border-indigo-800 cursor-pointer rounded-md text-indigo-800 p-1"
                                        onClick={() => table.nextPage()}
                                        disabled={!table.getCanNextPage()}
                                    >
                                        <MdNavigateNext className="h-5 w-5" />
                                    </button>
                                    <button
                                        className="hover:bg-indigo-100 border border-indigo-800 cursor-pointer rounded-md text-indigo-800 p-1"
                                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                        disabled={!table.getCanNextPage()}
                                    >
                                        <MdLastPage className="h-5 w-5" />
                                    </button>
                                </div>
                            </div>       
                            */}
    </div>
  );
};

export default memo(Table);
