
import { useState, useEffect } from "react"
import { FaTimes } from "react-icons/fa"
export default function Modal(props) {
    const { showModal = false, setShowModal } = props
    return (
        <>
            {
                showModal ?
                    <div className="fixed inset-0 bg-black bg-opacity-50 w-full h-full z-[99]">                        
                        <section className="fixed rounded p-16 bg-white w-[100%] md:w-[32rem] max-w-full h-auto top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                            <div className="pt-4 pr-4 right-0 absolute top-0">
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="p-2 text-indigo-500 bg-indigo-100 hover:bg-indigo-800 hover:text-white rounded">
                                        <FaTimes />
                                </button>
                            </div>                            
                            {props.children}
                        </section>
                    </div>
                    :
                    <></>
            }
        </>

    )
}