import { useState } from "react"
import Modal from "../../Modal/Modal"
import FilterSelector from "./FilterSelector"
import { MdFilterAlt } from "react-icons/md"
import DotsLoading from "../../DotsLoading"
export default function FilterConfiguration(props) {
    const { showModal, setShowModal, filterConfig, filterActionConfig = {} } = props
    const [options, setOptions] = useState([])
    const [selectorSelected, setSelectorSelected] = useState('')
    return (
        <Modal
            title={"Filtro"}
            description=""
            showModal={showModal}
            setShowModal={setShowModal}
        >
            <div className="relative h-full">
                <div className="h-full min-h-[500px] max-h-fit flex flex-col gap-4">
                    {
                        filterConfig.map((filter, index) => {
                            return (
                                <div 
                                    key={`selector_filter_${index}`} 
                                    style={{ 'zIndex': (98 - index) }}>
                                    <FilterSelector                                        
                                        config={filter.config}
                                        setOptions={filter.setConfig}
                                        selectorSelected={selectorSelected}
                                        setSelectorSelected={setSelectorSelected}
                                    />
                                </div>
                            )
                        })
                    }
                    <div className="w-full h-[400px]">

                    </div>
                </div>
                <div className="w-full absolute bottom-0 grid grid-cols-2 gap-4">
                    <button
                        onClick={(e) => setShowModal(false)}
                        className="flex justify-center items-center gap-2 w-full bg-slate-200 hover:bg-slate-300 text-slate-500 rounded-lg p-4 uppercase font-bold text-sm">
                        Cancelar
                    </button>
                    <button
                        onClick={(e) => filterActionConfig?.handler()}
                        className="flex justify-center items-center gap-2 w-full bg-indigo-700 hover:bg-indigo-800 rounded-lg text-white p-4 uppercase font-bold text-sm"
                    >
                        {
                            filterActionConfig?.filterAction?.loading ?
                                <DotsLoading />
                                :
                                <><MdFilterAlt /> Aplicar filtro</>
                        }

                    </button>
                </div>
            </div>
        </Modal>
    )
}