export default function authHeader() {
  const token = localStorage.getItem('apiToken');
  if (token) {
    return {
      'auth-token': token,
      'Content-Type': 'application/json'
    };
  } else {
    return {};
  }
}
