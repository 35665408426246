import Container from "../Container";
import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import LeadsService from "../../../services/leads.service";
import ReportsService from "../../../services/reports.service";
import Badge from "../Badge";
import StatusBadge from "../StatusBadge";
import { format } from "date-fns";
import {
  MdPersonAdd,
  MdBusiness,
  MdPerson,
  MdEmail,
  MdPhone,
  MdGpsFixed,
  MdCalendarMonth,
} from "react-icons/md";
import LeadForm from "./LeadForm";
import verifyAccess from "../../../auth/verifyAccess";
import PropertyTag from "./PropertyTag";
import ReactTable from "../../Table/ReactTable";
import { filterDataConfig } from "../../Utils/filterDataConfig";
import DotsLoading from "../../DotsLoading";
export default function Leads() {
  const navigate = useNavigate();
  const [dataLeads, setDataLeads] = useState([]);
  const [searchLimit, setSearchLimit] = useState(25);
  const [searchOffset, setSearchOffset] = useState(0);
  const [searchMore, setSearchMore] = useState({
    loading: false,
  });
  const [usersApp, setUsersApp] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingReport, setLoadingReport] = useState(false);
  const [showSlide, setShowSlide] = useState(false);
  const [filterLeads, setFilterLeads] = useState([]);
  const [filterConfig, setFilterConfig] = useState([]);
  const [filterAction, setFilterAction] = useState({
    loading: false,
    activeFilter: false,
  });
  const [filterActionConfig, setFilterActionConfig] = useState([]);
  const [propertiesSelector, setPropertiesSelector] = useState({
    selectorKey: "propertiesSelector",
    paramKey: "property",
    label: "Propiedad",
    type: "select",
    options: [],
    selectedOptions: [],
    selectorFocus: false,
  });
  const [segmentSelector, setSegmentSelector] = useState({
    selectorKey: "segmentSelector",
    paramKey: "target",
    label: "Segmento",
    type: "select",
    options: [],
    selectedOptions: [],
    selectorFocus: false,
  });
  const [createdDateSelector, setCreatedDateSelector] = useState({
    selectorKey: "createdDateSelector",
    paramKey: "created_at",
    label: "Fecha de creación",
    type: "date",
    selectorFocus: false,
    start: null,
    end: null,
  });
  const [lastFollowUpDateSelector, setLastFollowUpDateSelector] = useState({
    selectorKey: "lastFollowUpDateSelector",
    paramKey: "last_follow_up_date",
    label: "Último seguimiento",
    type: "date",
    selectorFocus: false,
    start: null,
    end: null,
  });
  const [statusSelector, setStatusSelector] = useState({
    selectorKey: "statusSelector",
    paramKey: "status",
    label: "Estatus",
    type: "select",
    options: [],
    selectedOptions: [],
    selectorFocus: false,
  });

  useEffect(() => {
    setFilterLeads(dataLeads);
  }, [dataLeads]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getLeads({ limit: searchLimit });
      formatFilter();
    }
    return () => (isSubscribed = false);
  }, []);

  const getLeads = (params = { limit: searchLimit, offset: 0 }) => {
    LeadsService.getLeads({ params }).then((response) => {
      if (response.status === 200) {
        const { leads, users } = response.data.data;
        if (params.offset > 0) {
          let mergeLeads = [...dataLeads, ...leads];          
          formatData(mergeLeads, users);
          setSearchMore({ ...searchMore, ...{ loading: false } });
        } else {
          formatData(leads, users);
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "Cliente",
        accessorKey: "nameFormat",
        cell: ({ row }) => {
          return <CustomLeadName lead={row.original} />;
        },
      },
      {
        header: "Segmento",
        accessorKey: "targetRequest",
        cell: ({ row }) => {
          const { targets, property } = row.original.clientRequest;
          return (
            <CustomTargetComponent targets={targets} property={property} />
          );
        },
      },
      {
        header: "Fecha de Creación",
        accessorKey: "leadDate",
        cell: ({ row }) => {
          return (
            <Badge
              icon={<MdCalendarMonth />}
              label={format(new Date(row.original.createdAt), "dd-MM-yyyy")}
            />
          );
        },
      },
      {
        header: "Status",
        accessorKey: "statusRequest",
        cell: ({ row }) => {
          const { statusOptions } = row.original.clientRequest;
          const { requestStatus = false } = statusOptions[0] ?? false;
          return (
            <div className="flex flex-col gap-2">
              {statusOptions[0] && (
                <StatusBadge
                  status={statusOptions[0]?.code}
                  label={statusOptions[0]?.description}
                />
              )}

              {requestStatus && (
                <Badge
                  icon={<MdCalendarMonth />}
                  label={format(
                    new Date(requestStatus?.createdAt),
                    "dd-MM-yyyy"
                  )}
                />
              )}
            </div>
          );
        },
      },
      {
        header: "",
        accessorKey: "details",
        cell: ({ row }) => {
          return (
            <a
              className="text-blue-600 font-bold hover:underline"
              rel="noreferrer"
              target="_blank"
              href={`/leads/${row.original.uuid}`}
            >
              Detalles
            </a>
          );
        },
      },
    ],
    [filterLeads]
  );

  const data = useMemo(() => filterLeads, [filterLeads]);

  const formatData = (data, users) => {
    setDataLeads(data);
    setUsersApp(users);
    setLoading(false);
  };

  const CustomLeadName = (props) => {
    const { lead } = props;
    const fullName = `${lead?.name ?? ""} ${lead?.lastName ?? ""}`;
    return (
      <>
        <div className="flex flex-col">
          {lead.clientCompany && (
            <span className="flex items-center gap-2 font-bold">
              <MdBusiness /> {lead?.clientCompany?.name}
            </span>
          )}
          <span className="!text-sm flex items-center gap-2 font-bold">
            <MdPerson /> {fullName}
          </span>
          <span className="!text-xs flex items-center gap-2">
            <MdEmail /> {lead.email}
          </span>
          {lead?.phone && (
            <span className="!text-xs flex items-center gap-2">
              <MdPhone /> {lead.phone}
            </span>
          )}
        </div>
      </>
    );
  };

  const CustomTargetComponent = (props) => {
    const { targets, property } = props;
    return (
      <div className="flex flex-col gap-2">
        {targets[0]?.description && (
          <Badge icon={<MdGpsFixed />} label={targets[0]?.description} />
        )}
        {property?.description && (
          <PropertyTag label={property?.description} type={property?.code} />
        )}
      </div>
    );
  };

  const handleLeadForm = (item) => {
    setShowSlide(true);
  };

  const handleFilterLeads = (e) => {
    const filterValue = e.target.value;
    if (filterValue === "all") {
      setFilterLeads([...dataLeads]);
      return;
    }
    let filter = [...dataLeads];
    filter = filter.filter((item) => {
      const cReq = item?.clientRequest;
      return cReq?.targets[0]?.code === filterValue;
    });
    setFilterLeads(filter);
  };

  const formatFilter = () => {
    setPropertiesSelector({
      ...propertiesSelector,
      options: filterDataConfig().properties,
    });
    setSegmentSelector({
      ...segmentSelector,
      options: filterDataConfig().segments,
    });
    setStatusSelector({
      ...statusSelector,
      options: filterDataConfig().status,
    });
    setFilterAction({
      ...filterAction,
      ...{
        loading: false,
      },
    });
  };

  useEffect(() => {
    setFilterConfig([
      {
        config: propertiesSelector,
        setConfig: setPropertiesSelector,
      },
      {
        config: segmentSelector,
        setConfig: setSegmentSelector,
      },
      {
        config: createdDateSelector,
        setConfig: setCreatedDateSelector,
      },
      {
        config: lastFollowUpDateSelector,
        setConfig: setLastFollowUpDateSelector,
      },
      {
        config: statusSelector,
        setConfig: setStatusSelector,
      },
    ]);
    setFilterActionConfig({
      filterAction,
      setFilterAction,
      handler: handleFilterAction,
    });
  }, [
    propertiesSelector,
    segmentSelector,
    createdDateSelector,
    lastFollowUpDateSelector,
    statusSelector,
    filterAction,
  ]);

  const handleFilterAction = () => {
    setFilterAction({ ...filterAction, loading: true, activeFilter: true });
    //recollect filter data for request
    setLoading(true);
    const params = formatSelectedFilter([
      propertiesSelector,
      segmentSelector,
      createdDateSelector,
      lastFollowUpDateSelector,
      statusSelector,
    ]);
    getLeads(params);
  };

  const formatSelectedFilter = (filters) => {
    try {
      let params = {};
      for (const filter of filters) {
        let paramValue = [];
        if (filter.type === "select") {
          paramValue = filter.selectedOptions.map((option) => option.key);
        }
        if (filter.type === "date") {
          //Omit param when value eq 0 || null
          const omitValues = ["", 0, null];
          if (
            !omitValues.includes(filter.start) &&
            !omitValues.includes(filter.end)
          ) {
            paramValue = [
              format(new Date(filter.start), "yyyy-MM-dd"),
              format(new Date(filter.end), "yyyy-MM-dd"),
            ];
          }
        }
        params[filter.paramKey] = paramValue.toString();
      }
      return params;
    } catch (error) {
      return {};
    }
  };

  const downloadFile = (file, fileName) => {
    const fetcher = ReportsService.downloadLeadsReport;
    const params = formatSelectedFilter([
      propertiesSelector,
      segmentSelector,
      createdDateSelector,
      lastFollowUpDateSelector,
      statusSelector,
    ]);
    setLoadingReport(true);
    fetcher({ params }).then((response) => {
      if (response.status === 200) {
        try {
          const fileName = `crm-report-${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;
          link.click();
          setLoadingReport(false);
          return;
        } catch (error) {
          setLoadingReport(false);
          console.log(error);
        }
      } else {
        setLoadingReport(false);
        console.log("Ocurrió un error al procesar la información.");
      }
    });
  };

  const handleShowMore = () => {
    const newOffset = searchOffset + searchLimit;
    setSearchOffset(newOffset);
    setSearchMore({ ...searchMore, ...{ loading: true } });
    getLeads({ limit: searchLimit, offset: newOffset });
  };

  return (
    <>
      <Container>
        <div className="flex items-center w-full justify-between">
          <h1 className="font-bold text-2xl">Seguimientos</h1>
          <button
            onClick={() => handleLeadForm()}
            className="flex items-center gap-2 py-2 px-4 bg-indigo-700 hover:bg-indigo-500 text-white rounded-lg font-bold"
          >
            Nuevo
            <MdPersonAdd />
          </button>
        </div>
        <div className="pt-8">
          <ReactTable
            exportData={downloadFile}
            loadingReport={loadingReport}
            setLoadingReport={setLoadingReport}
            filterConfig={filterConfig}
            filterActionConfig={filterActionConfig}
            filterKeys={["name", "lastName", "email"]}
            tableColumns={columns}
            tableItems={data}
            loading={loading}
          />
          {!filterAction.activeFilter && !loading && (
            <div className="flex justify-center py-8">
              <button
                onClick={() => handleShowMore()}
                className={`px-4 py-2 bg-indigo-700 text-white rounded-lg font-bold min-w-[200px] tracking-wider ${searchMore.loading ? 'loading-state': ''}`}
              >
                {searchMore.loading ? (
                  <DotsLoading />
                ) : (
                  <span>Cargar más... </span>
                )}
              </button>
            </div>
          )}
        </div>
      </Container>
      <LeadForm showSlide={showSlide} setShowSlide={setShowSlide} />
    </>
  );
}
